/* eslint-disable prettier/prettier */
import { ErrorText, IInput } from "./Input";
/* eslint-disable react/jsx-props-no-spreading */
import { useController, useFormContext } from "react-hook-form";

import { Form } from "react-bootstrap";
import React from "react";
import get from "lodash/get";
import styled from "styled-components";
import { colors } from "@theme/colors";

const CustomCheck = styled(Form.Check)`
  &.form-check {
    display: flex;
    align-items: center;
  }
  .form-check-label {
    padding-left: 8px;
  }
  .form-check-input {
    border: 3px solid ${colors.brandColorPrimary} !important;
    width: 32px;
    height: 32px;
    position: relative;
  }
  .form-check-input:focus {
    box-shadow: none;
  }
  .form-check-input:checked {
    background-color: white;
  }
  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23${colors.brandColorPrimary.split('#')[1]}%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
  }
`;

const CheckBox: React.FC<IInput> = ({
  name,
  label,
  isRequired = false,
  helperText,
  disabled = false,
}) => {
  const { field } = useController({ name });
  const {
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name)?.message;
  return (
    <Form.Group className="mt-4">
      <CustomCheck
        {...field}
        defaultChecked={field.value}
        disabled={disabled}
        label={
          <div>
            {label} {isRequired && <span style={{ color: "red" }}>*</span>}
          </div>
        }
      />
      <Form.Text>{helperText}</Form.Text>
      {error && <ErrorText>{String(error)}</ErrorText>}
    </Form.Group>
  );
};

export default CheckBox;
