/* eslint-disable react/button-has-type */
import "react-activity/dist/library.css";

import MaterialIcon, { TIcon } from "@components/MaterialIcon";

import React from "react";
import { Spinner } from "react-activity";
import _ from "lodash";
import classNames from "classnames";

export interface IIconProp {
  icon: TIcon;
  position: "left" | "right";
  size: 12 | 18 | 20 | 24 | 40 | 60;
}

export interface IButtonProps {
  className?: string;
  disabled?: boolean;
  icon?: IIconProp;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  route?: string;
  size?: "small" | "medium" | "medium-big" | "big";
  type?: "button" | "submit";
  typeStyle?: "block";
  variant?: "link";
  value: string;
}

const Icon = ({
  className,
  icon,
  disabled,
}: {
  className: string;
  icon: IIconProp;
  disabled?: boolean;
}) => (
  <MaterialIcon
    className={`c-button__icon ${className}`}
    icon={icon!.icon}
    size={icon!.size}
    color={disabled ? "o-cl-grey-200" : "o-cl-brand-secondary"}
  />
);

const Button = ({
  className,
  disabled,
  icon,
  isLoading,
  onClick,
  size = "medium",
  route,
  type = "button",
  variant,
  typeStyle,
  value,
}: IButtonProps) => {
  const buttonClasses = classNames({
    "c-button--block": _.isEqual(typeStyle, "block"),
    "o-ft-2xs-600": _.isEqual(size, "small"),
    "o-ft-xs-700": _.isEqual(size, "medium"),
    "o-ft-base-700 o-ft-xl-700@md": _.isEqual(size, "medium-big"),
    "o-ft-xl-700": _.isEqual(size, "big"),
    [`c-button--${size}`]: size,
    [`${className}`]: className,
    "c-button--link": _.isEqual(variant, "link"),
  });

  return _.isUndefined(route) ? (
    <button
      className={`c-button o-bg-brand-secondary:hover o-cl-brand-secondary o-cl-white:hover d-flex align-items-center justify-content-center ${buttonClasses}`}
      {...{ onClick, type, disabled }}
    >
      {icon && !isLoading && icon.position === "left" && (
        <Icon className="me-2" icon={icon} disabled={disabled} />
      )}
      <span className="c-button__text">
        {value}
        {isLoading && <Spinner className="c-button__spinner" />}
      </span>
      {icon && !isLoading && icon.position === "right" && (
        <Icon
          className={`ms-2 ${_.isEqual(variant, "link") && "c-button__icon--link"}`}
          icon={icon}
          disabled={disabled}
        />
      )}
    </button>
  ) : (
    <a
      href={route}
      className={`c-button o-bg-brand-secondary:hover o-cl-brand-secondary o-cl-white:hover d-flex align-items-center justify-content-center ${buttonClasses}`}
      {...{ disabled }}
    >
      {icon && !isLoading && icon.position === "left" && (
        <Icon className="me-2" icon={icon} disabled={disabled} />
      )}
      {isLoading ? (
        <Spinner className="c-button__spinner" />
      ) : (
        <span className="c-button__text">{value}</span>
      )}
      {icon && !isLoading && icon.position === "right" && (
        <Icon className="ms-2" icon={icon} disabled={disabled} />
      )}
    </a>
  );
};

export default Button;
