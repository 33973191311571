/* eslint-disable react/jsx-props-no-spreading */
import { ErrorText, IInput } from "./Input";
import { useController, useFormContext } from "react-hook-form";

import { Form } from "react-bootstrap";
import LexicalWysWyg from "@components/LexicalWysWyg/LexicalWysWyg";
import React from "react";
import get from "lodash/get";

const TextArea: React.FC<IInput> = ({
  name,
  label,
  isRequired = false,
  helperText,
}) => {
  const { field } = useController({ name });
  const {
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name)?.message;

  return (
    <Form.Group className="mt-4">
      <Form.Label>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Form.Label>
      <LexicalWysWyg {...field} initialValue={field.value} />
      <Form.Text>{helperText}</Form.Text>
      {error && <ErrorText>{String(error)}</ErrorText>}
    </Form.Group>
  );
};

export default TextArea;
