/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postTag } from "@redux/tags/thunks/tagThunk";
import { TTag, TTagData } from "@models/Tag.type";
import _ from "lodash";

export interface ITagSlice {
  data: TTag[]; // all tags (suggestions)
  pending: boolean;
  tagsByDocuments: TTag[]; // all tags by document
  tagsByDocumentsEditable: TTag[]; // all tags by document
  currentTagValue: string;
}

const initialState: ITagSlice = {
  data: [],
  pending: false,
  tagsByDocuments: [],
  tagsByDocumentsEditable: [],
  currentTagValue: "",
};

export const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    setCurrentTagValue: (state: ITagSlice, action: PayloadAction<string>) => {
      state.currentTagValue = action.payload;
    },
    removeTag: (state: ITagSlice, action: PayloadAction<{ tag: TTag }>) => {
      const { tag } = action.payload;
      state.tagsByDocumentsEditable = state.tagsByDocumentsEditable.filter(
        (o) => !_.isEqual(o.id, tag.id),
      );
    },
    addTag: (state: ITagSlice, action: PayloadAction<{ tag: TTag }>) => {
      const { tag } = action.payload;
      state.tagsByDocumentsEditable = [...state.tagsByDocumentsEditable, tag];
    },
    clearTagsByDocumentsEditable: (state: ITagSlice) => {
      state.tagsByDocumentsEditable = [];
    },
    updateTagsByDocumentsEditable: (
      state: ITagSlice,
      action: PayloadAction<TTag[]>,
    ) => {
      state.tagsByDocuments = action.payload;
      state.tagsByDocumentsEditable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postTag.fulfilled, (state, action: PayloadAction<any>) => {
      state.pending = false;
    });
    builder.addCase(postTag.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(postTag.pending, (state) => {
      state.pending = true;
    });
  },
});

export const {
  setCurrentTagValue,
  removeTag,
  addTag,
  clearTagsByDocumentsEditable,
  updateTagsByDocumentsEditable,
} = tagSlice.actions;
export default tagSlice.reducer;
