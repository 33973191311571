import React, { useEffect, useState } from "react";
import classNames from "classnames";
import useWindowSize from "@hooks/useWindowSize";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface DateHourWidgetProps {}

const DateHourWidget: React.FC<DateHourWidgetProps> = () => {
  const { responsive } = useWindowSize();
  const [date, setDate] = useState(new Date());
  function refreshClock() {
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const alignmentEndClass = classNames({
    "justify-content-end text-end": responsive.lg,
  });

  return (
    <div className={`d-flex flex-column flex-grow-1 ${alignmentEndClass}`}>
      <span className="o-ft-2xl-400">{date.getFullYear()}</span>
      {responsive.lg && (
        <span className="o-ft-5xl-700"> {`${date.getDate()}`}</span>
      )}
      <div className={`d-flex flex-row ${alignmentEndClass}`}>
        <span className="c-date-hour-widget-month o-ft-2xl-300 o-ft-2xl-400@lg">{`${monthNames[date.getMonth()]}`}</span>
        {!responsive.lg && (
          <span className="o-ft-2xl-600">&nbsp;{`${date.getDate()}`}</span>
        )}
        {/* <span>{date.toLocaleString('default', { month: 'long' })}</span> */}
      </div>
      {responsive.lg && <div className="border my-2" />}
      <span className="o-ft-xl-300 o-ft-xl-400@lg o-cl-brand-primary text-end">
        {date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </span>
    </div>
  );
};

export default DateHourWidget;
