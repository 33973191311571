/* eslint-disable react/jsx-props-no-spreading */
import { ErrorText, IInput } from "./Input";
import React, { useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

import Button from "@components/Button";
import { Form } from "react-bootstrap";
import MaterialIcon from "@components/MaterialIcon";
import get from "lodash/get";
import styled from "styled-components";
import { useDroppable } from "@dnd-kit/core";
import { colors } from "@theme/colors";

const InputFileBox = styled.div<{ isDragging: boolean }>`
  border: 1px dashed
    ${({ isDragging }) => (isDragging ? colors.brandColorPrimary : "#9fa0a0")};
  border-radius: 3px;
`;

const InputFileAttach = styled.div`
  height: 330px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const InputFilePreview = styled.div`
  height: 330px;
  display: flex;
`;

const ImageContainer = styled.div`
  padding: 1.5rem;
  width: 70%;
  height: 100%;
`;

const InputFileWrapper = styled.label``;

const DetailsContainer = styled.div`
  padding: 1.5rem 1.5rem 1.5rem 0;
  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface ICoverImageInput extends Omit<IInput, "label"> {
  label?: string;
}

const CoverImage: React.FC<ICoverImageInput> = ({
  name,
  label = "",
  isRequired = false,
  placeholder,
  disabled = false,
}) => {
  const { field } = useController({ name });
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const error = get(errors, name)?.message;
  const [imageSrc, setImageSrc] = useState("");
  const [imageName, setImageName] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      field.onChange(input.files[0]);
    }
    const reader = new FileReader();
    reader.onload = function () {
      if (typeof reader.result === "string") {
        setImageSrc(reader.result);
      }
      return "";
    };
    if (input.files !== null) {
      setImageName(input.files[0].name);
      reader.readAsDataURL(input.files[0]);
    }
  };

  const currentImageData = watch(name);
  useEffect(() => {
    if (currentImageData?.url) {
      setImageSrc(currentImageData.url);
      setImageName(currentImageData.original_name || "Uploaded Image");
    }

    if (currentImageData instanceof File) {
      const reader = new FileReader();
      reader.onload = function () {
        if (typeof reader.result === "string") {
          setImageSrc(reader.result);
        }
        return "";
      };
      if (currentImageData !== null) {
        setImageName(currentImageData.name);
        reader.readAsDataURL(currentImageData);
      }
    }
  }, [currentImageData]);

  const deleteCoverImage = () => {
    setImageSrc("");
    setImageName("");
    setValue(name, null);
  };

  const { setNodeRef } = useDroppable({
    id: "dropzone",
  });

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const { files } = event.dataTransfer;
    if (files && files[0]) {
      const file = files[0];
      field.onChange(file);

      const reader = new FileReader();
      reader.onload = function () {
        if (typeof reader.result === "string") {
          setImageSrc(reader.result);
        }
        return "";
      };
      setImageName(file.name);
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  return (
    <Form.Group className="mt-2 o-ft-xs-600 o-cl-grey-200">
      <Form.Label>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Form.Label>
      <InputFileBox
        ref={setNodeRef}
        isDragging={isDragging}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {imageName === "" ? (
          <InputFileAttach>
            <span className="o-cl-grey-200 o-ft-lg-400 mb-4">
              Drag and drop an image or:
            </span>
            <InputFileWrapper className="c-file-upload c-file-upload--big o-bg-brand-secondary:hover o-cl-brand-secondary o-cl-white:hover o-ft-xs-700 d-flex align-items-center justify-content-center">
              Attach Image
              <MaterialIcon
                className="c-file-upload__icon ms-2 rotate-270"
                color={disabled ? "o-cl-grey-200" : "o-cl-brand-secondary"}
                icon="attachment"
                // size={icon.size}
              />
              <Form.Control
                {...field}
                type="file"
                accept="image/*"
                placeholder={placeholder}
                disabled={disabled}
                value={field.value?.fileName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleOnChange(event)
                }
              />
            </InputFileWrapper>
          </InputFileAttach>
        ) : (
          <InputFilePreview>
            <ImageContainer>
              <img
                src={imageSrc}
                alt="Imagen"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </ImageContainer>
            <DetailsContainer>
              <span className="o-ft-lg-700">{imageName}</span>
              <Button
                value="Delete Image"
                className="my-2"
                onClick={deleteCoverImage}
              />
              <span className="o-ft-xs-400">
                *The cover image size respects the dimensions in the final post.
              </span>
            </DetailsContainer>
          </InputFilePreview>
        )}
      </InputFileBox>
      {error && <ErrorText>{String(error)}</ErrorText>}
    </Form.Group>
  );
};

export default CoverImage;
