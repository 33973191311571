import Button from "@components/Button";
import TagInput from "@components/TagInput";
import ActionModal from "@components/ActionModal";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import {
  clearTagsByDocumentsEditable,
  setCurrentTagValue,
  removeTag,
  updateTagsByDocumentsEditable,
} from "@redux/tags/slices/tagSlice";
import React, { useEffect } from "react";
import {
  useGetDocumentsTags,
  useGetTags,
  useUpdateDocument,
} from "@redux/documents/api";
import { TagList } from "@components/TagList";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import { isEqual, sortBy } from "lodash";
import { TTag } from "@models/Tag.type";
import { updateDocumentTags } from "@redux/files/slices/fileSlice";
import { createToast } from "@helpers/createToast";

interface ITagModalProps {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
}

export const TagModal = ({ isVisible, setVisible }: ITagModalProps) => {
  const { isFetching: isFetchingTags } = useGetTags();
  const {
    file: { elementToTag },
    tags: { tagsByDocumentsEditable },
  } = useAppSelector((state) => state);
  const [updateDocument, { isLoading }] = useUpdateDocument();
  const dispatch = useAppDispatch();

  const { data: documentTags, isFetching: isFetchingDocumentTags } =
    useGetDocumentsTags(
      { id: elementToTag?.id },
      {
        skip: elementToTag === null,
      },
    );

  const handlePostTags = () => {
    if (
      !isEqual(
        sortBy(
          documentTags.data.map((tag: TTag) => tag.id),
          "id",
        ),
        sortBy(
          tagsByDocumentsEditable.map((tag: TTag) => tag.id),
          "id",
        ),
      )
    ) {
      updateDocument({
        id: elementToTag?.id,
        name: elementToTag?.name || "",
        parent_id: elementToTag?.parent_id || 0,
        document_tags: tagsByDocumentsEditable.map((tag: TTag) =>
          Number(tag.id),
        ),
      })
        .unwrap()
        .then(() => {
          dispatch(
            updateDocumentTags({
              id: elementToTag?.id || 0,
              tags: tagsByDocumentsEditable,
            }),
          );
          createToast("Tags updated successfully", "success", dispatch);
          setVisible(false);
        });
    }
    setVisible(false);
  };

  useEffect(() => {
    if (isVisible && documentTags) {
      dispatch(updateTagsByDocumentsEditable(documentTags.data));
    }
  }, [isVisible]);

  return (
    <ActionModal
      show={isVisible}
      title={
        <span>
          Tags for: <span className="o-cl-grey-200">{elementToTag?.name}</span>
        </span>
      }
      includesForm
      renderContent={
        isFetchingDocumentTags || isFetchingTags ? (
          <div className="d-flex justify-content-center mt-5">
            <Spinner
              className="v-contacts__spinner"
              color={colors.brandColorSecondary}
            />
          </div>
        ) : (
          <>
            <TagInput label="Tag" placeholder="# Tag" name="" />
            <TagList
              list={tagsByDocumentsEditable}
              onClick={(tag) => dispatch(removeTag({ tag }))}
              documentId={elementToTag?.id || 0}
            />
            <div className="d-flex w-100 mt-4 align-items-center justify-content-between pt-1">
              <Button
                className="w-100 mx-2 mx-md-4"
                onClick={() => {
                  setVisible(false);
                  dispatch(setCurrentTagValue(""));
                  dispatch(clearTagsByDocumentsEditable());
                }}
                size="medium-big"
                value="Cancel"
              />
              <Button
                isLoading={isLoading}
                disabled={!!isLoading}
                className="w-100 mx-2 mx-md-4"
                onClick={handlePostTags}
                size="medium-big"
                value="Continue"
              />
            </div>
          </>
        )
      }
    />
  );
};
