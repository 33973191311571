/* eslint-disable @typescript-eslint/no-explicit-any */
import PostForm from "@sections/Posts/PostForm";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetPost,
  useGetAdminFeaturedPost,
  IPost,
  IPostFile,
} from "@redux/posts/api";
import useAppSelector from "@hooks/useAppSelector";
import { setDraftPost } from "@redux/posts/slice";
import useAppDispatch from "@hooks/useAppDispatch";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import { base64ToFile } from "../../../utils/utils";

export interface PostFormData {
  id?: string;
  name: string;
  subtitle: string;
  content: string;
  image_caption: string | null;
  post_tags: any;
  coverImage: any | null;
  is_published: boolean;
  is_featured: boolean;
  is_author_visible: boolean;
  file?: IPostFile;
  fileName?: string;
}

const ViewPostForm = () => {
  const { postId } = useParams();
  const dispatch = useAppDispatch();
  const { data: featuredPost, isFetching: isFetchingFeaturedPost } =
    useGetAdminFeaturedPost();
  const { post: draftPost } = useAppSelector((state) => state.post);

  const [defaultValues, setDefaultValues] = useState<
    IPost | PostFormData | null
  >(null);

  const { data: post, isFetching } = useGetPost(
    { id: postId },
    { skip: postId === undefined },
  );

  useEffect(() => {
    setDefaultValues(null);
    if (!isFetching && !isFetchingFeaturedPost && featuredPost) {
      const baseValues: IPost | PostFormData = {
        ...draftPost,
        is_featured: featuredPost.data.length < 3,
      };
      dispatch(
        setDraftPost({
          data: baseValues,
        }),
      );

      if (post) {
        if (!draftPost.id) {
          const updatedPost = {
            ...post.data,
            coverImage: post.data.file,
            post_tags: post.data.post_tags.map(
              (tag: { name: string; id: string }) => tag.id,
            ),
          };
          dispatch(
            setDraftPost({
              data: updatedPost,
            }),
          );
          setDefaultValues(updatedPost);
        } else {
          // Enter when go to preview post and go back
          const file =
            typeof baseValues.coverImage === "string"
              ? base64ToFile(baseValues.coverImage, baseValues.fileName || "")
              : baseValues.coverImage;
          const updatedDraftPost = {
            ...draftPost,
            post_tags: draftPost.post_tags.map(
              (tag: { name: string; id: string }) => tag.id,
            ),
            coverImage: file,
          };
          setDefaultValues(updatedDraftPost);
        }
      } else {
        // Enter on create post
        const file = baseValues.coverImage
          ? base64ToFile(baseValues.coverImage, baseValues.fileName || "")
          : undefined;
        const updatedBaseValues = {
          ...baseValues,
          post_tags: draftPost.post_tags.map(
            (tag: { name: string; id: string }) => tag.id,
          ),
          coverImage: file,
        };

        setDefaultValues(updatedBaseValues);
      }
    }
  }, [featuredPost, post, isFetchingFeaturedPost, isFetching]);

  return isFetching || isFetchingFeaturedPost || !defaultValues ? (
    <div className="d-flex justify-content-center mt-5 flex-column align-items-center gap-4">
      <Spinner
        className="o-ft-xl o-ft-3xl@md"
        color={colors.brandColorSecondary}
      />
    </div>
  ) : (
    <PostForm initialValues={defaultValues} />
  );
};

export default ViewPostForm;
