/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useShowDocument } from "./fetchs/useShowDocument";

export interface IDownloadableFile {
  id: string | number;
}

export const useDownloadOriginalFiles = () => {
  const { getData } = useShowDocument();

  const downloadFileWithUrl = (url: string, name: string) => {
    return new Promise((resolve) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        resolve("");
      }, 1000);
    });
  };
  const downloadFile = async (fileId: string | number) => {
    const e = await getData(fileId);
    const { download_url: downloadUrl } = e.data.file;

    const { name, type } = e.data;

    await downloadFileWithUrl(downloadUrl, `${name}.${type}`);
  };

  const downloadMultipleOriginalFiles = async (
    files: Array<IDownloadableFile>,
  ) => {
    for (const file of files) {
      await downloadFile(file.id);
    }
  };

  return {
    downloadMultipleOriginalFiles,
    downloadFile,
  };
};
