/* eslint-disable prettier/prettier */
import { ErrorText, IInput } from "./Input";
/* eslint-disable react/jsx-props-no-spreading */
import { useController, useFormContext } from "react-hook-form";

import { Form } from "react-bootstrap";
import React from "react";
import get from "lodash/get";
import styled from "styled-components";
import { colors } from "@theme/colors";

const CustomSwitch = styled(Form.Switch)`
  .form-check-input {
    height: 16px;
    border: 3px solid;
  }
  &.form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 10 10'%3e%3ccircle r='3' fill='%23565250'/%3e%3c/svg%3e");
    border-color: #565250;
  }
  &.form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 10 10'%3e%3ccircle r='3' fill='%23${colors.brandColorPrimary.split('#')[1]}'/%3e%3c/svg%3e");
    background-color: transparent;
    border-color: ${colors.brandColorPrimary};
  }
  .form-check-input:focus {
    box-shadow: none;
  }
`;

const Switch: React.FC<IInput> = ({
  name,
  label,
  isRequired = false,
  helperText,
  disabled = false,
}) => {
  const { field } = useController({ name });
  const {
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name)?.message;
  return (
    <Form.Group className="mt-4">
      <CustomSwitch
        {...field}
        disabled={disabled}
        defaultChecked={field.value}
        label={
          <div>
            {label} {isRequired && <span style={{ color: "red" }}>*</span>}
          </div>
        }
      />
      <Form.Text>{helperText}</Form.Text>
      {error && <ErrorText>{String(error)}</ErrorText>}
    </Form.Group>
  );
};

export default Switch;
