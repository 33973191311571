/* eslint-disable @typescript-eslint/no-explicit-any */
import TagController from "@controllers/TagController";
import { errorRequest } from "@helpers/errorRequest";
import { formatNotificationNoty } from "@helpers/formatNotificationNoty";
import { EToastTypes } from "@models/toast/Toast.type";
import { addToast } from "@redux/toasts/slices/toastsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const postTag = createAsyncThunk(
  "tag/post",
  async (
    { tags: _tags, documentId }: { tags: Array<string>; documentId: number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const tags: Array<string> = Array.from(new Set<string>(_tags));

      const response: any = await TagController.add(tags, documentId);
      const notification = {
        text: "Tags updated",
        description: "Tags have been updated successfully.",
      };

      if (window.Noty) {
        new window.Noty({
          type: "success",
          text: formatNotificationNoty(notification),
        }).show();
      } else {
        const toast = {
          id: Date.now(),
          text: notification.description,
          type: EToastTypes.PRIMARY,
          autohide: true,
        };
        dispatch(addToast(toast));
      }
      return response?.data;
    } catch (err: any) {
      return errorRequest(err, rejectWithValue, dispatch);
    }
  },
);
