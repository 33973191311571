import React from "react";
import useWindowSize from "@hooks/useWindowSize";
import emptyState from "@assets/no-results.svg";

const NoResult = () => {
  const baseSrcUrl =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "";
  const { responsive } = useWindowSize();
  return (
    <div className="d-flex justify-content-center flex-column align-items-center h-100">
      <img
        src={`${baseSrcUrl}${emptyState}`}
        alt="empty state"
        width={responsive.md ? 350 : 250}
      />
      <h2>No results found</h2>
      <div>We couldn&apos;t find any matches for your search</div>
      <div className="mb-5">Please try again</div>
    </div>
  );
};

export default NoResult;
