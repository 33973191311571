import React from "react";
import RenderPost from "@sections/Posts/RenderPost";
import useAppSelector from "@hooks/useAppSelector";
import Button from "@components/Button";
import { useNavigate } from "react-router-dom";
import { ChipContainer, Chip, ButtonContainer, PostContainer } from "./styled";
import { colors } from "@theme/colors";

const PreviewPost = () => {
  const { post } = useAppSelector((state) => state.post);
  const navigate = useNavigate();
  if (post) {
    return (
      <div className="d-flex flex-column align-items-center gap-2">
        <PostContainer>
          <ChipContainer $backgroundColor={colors.brandColorPrimary}>
            <Chip $color={colors.white}>
              {post.is_published ? "PUBLISHED" : "DRAFT"}
            </Chip>
          </ChipContainer>
        </PostContainer>
        <RenderPost post={post} />
        <ButtonContainer>
          <Button value="Go back" onClick={() => navigate(-1)} />
        </ButtonContainer>
      </div>
    );
  }

  return <div>carg</div>;
};

export default PreviewPost;
