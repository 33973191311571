/* eslint-disable no-plusplus */
export function fixUrl(url: string): string {
  return url.replace(/\\/g, "");
}

// Función para truncar el texto
export const truncateText = (text: string, wordLimit: number): string => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return `${words.slice(0, wordLimit).join(" ")}...`;
  }
  return text;
};

export const truncateTextToFit = (
  text: string,
  maxHeight: number,
  maxWidth: number,
  id: string,
  height: string = "75px",
): string => {
  const measurer = document.getElementById(id);

  if (!measurer) {
    return text; // Si no se encuentra el contenedor de medición, devuelve el texto original
  }

  measurer.style.display = "block"; // Muestra el contenedor para medir
  measurer.style.width = `${maxWidth}px`;
  measurer.innerText = text;

  if (measurer.offsetHeight <= maxHeight) {
    measurer.style.height = height;
    measurer.style.display = "block"; // Oculta el contenedor nuevamente
    return text;
  }

  const words = text.split(" ");
  let truncatedText = "";

  for (let i = 0; i < words.length; i++) {
    truncatedText += `${words[i]} `;
    measurer.innerText = `${truncatedText.trim()}...`;

    if (measurer.offsetHeight > maxHeight) {
      return `${truncatedText.trim()}...`;
    }
  }

  measurer.style.display = "none"; // Oculta el contenedor nuevamente
  return text;
};

export const base64ToFile = (base64String: string, filename: string) => {
  // Extrae el tipo MIME de la cadena base64
  const mimeTypeMatch = base64String.match(
    /^data:([a-zA-Z0-9+_.-]+\/[a-zA-Z0-9+_.-]+);base64,/,
  );
  const mimeType = mimeTypeMatch ? mimeTypeMatch[1] : "";

  // Elimina el prefijo de data URL
  const base64Data = base64String.replace(
    /^data:[a-zA-Z0-9+_.-]+\/[a-zA-Z0-9+_.-]+;base64,/,
    "",
  );

  // Convierte base64 a un array de bytes
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Crea un Blob a partir del array de bytes
  const blob = new Blob([byteArray], { type: mimeType });

  // Crea un objeto File a partir del Blob
  return new File([blob], filename, { type: mimeType });
};
