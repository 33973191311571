/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { IPost } from "@redux/posts/api";
import { formatDate } from "@helpers/formatDate";
import {
  ImageContainer,
  PostContainer,
  ChipContainer,
  Chip,
  Content,
} from "@views/Admin/Posts/styled";
import profileImg from "@assets/profile-img.png";
import { PostFormData } from "@views/Admin/Posts/ViewPostForm";

const RenderPost: React.FC<{ post: IPost | PostFormData }> = ({ post }) => {
  const baseSrcUrl =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "";
  return (
    <>
      <ImageContainer>
        {"coverImage" in post ? (
          <img src={post.coverImage?.url || post.coverImage} alt={post.name} />
        ) : (
          "file" in post &&
          post.file && <img src={post.file.url} alt={post.name} />
        )}
      </ImageContainer>
      <PostContainer>
        <div>
          <span className="o-ft-600 o-cl-grey-200">
            {"created_at" in post &&
              post.created_at &&
              formatDate(post.created_at)}
          </span>
        </div>
        <div>
          <span className="o-ft-5xl-600 o-cl-grey-100">
            {post.name && post.name}
          </span>
        </div>
      </PostContainer>
      <PostContainer>
        <div className="d-flex align-content-center align-items-center">
          <div className="c-avatar__user-picture">
            {"author" in post && post.author && post.is_author_visible && (
              <img
                className="header__logo c-avatar__picture"
                src={
                  post.author.avatar_file !== null
                    ? post.author.avatar_file.url
                    : `${baseSrcUrl}${profileImg}`
                }
                alt="profile"
              />
            )}
          </div>
          <span className="o-ft-700 o-cl-grey-100">
            {"author" in post && post.author && post.author.name}
          </span>
        </div>
        <div className="d-flex gap-2">
          {post.post_tags &&
            post.post_tags.map((tag: any) => (
              <ChipContainer>
                <Chip>{tag.name}</Chip>
              </ChipContainer>
            ))}
        </div>
        <div>
          <span className="o-ft-2xl-600 o-cl-grey-100">
            {post.subtitle && post.subtitle}
          </span>
        </div>
        {post.content && (
          <Content dangerouslySetInnerHTML={{ __html: post.content }} />
        )}
      </PostContainer>
    </>
  );
};

export default RenderPost;
