import Axios from "axios";
import _ from "lodash";

type TMethodType = "get" | "post" | "put" | "patch" | "delete";
interface IRequest {
  method: TMethodType;
  url: string;
  params?: any;
}

export const getCookie = (c_name: string) => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return document.cookie.substring(c_start, c_end);
    }
  }
  return "";
};

const baseUrlDefault = process.env.REACT_APP_BACK_BASE_URL!.slice(0, -1);

export const baseURL =
  decodeURIComponent(getCookie("react_app_back_base_url")) || baseUrlDefault;

const axios = Axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    // 'Content-Type': `multipart/form-data`,
    "Content-Type": `application/json`,
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Credentials": true,
  },
});

export default class AdminHttpProvider {
  request = async (
    method: IRequest["method"],
    url: IRequest["url"],
    params?: IRequest["params"]
  ) => {
    const token = getCookie("access_token");
    axios?.interceptors.request.use(
      (config) => {
        if (token && config.headers) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        if (_.isEqual(method, "put") && config.headers) {
          config.headers["Content-Type"] = `application/json`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    return axios[method](url, params);
  };
}
