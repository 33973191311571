/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "@api/AdminHttpProvider";

const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

export const tagsApi = createApi({
  reducerPath: "tagsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token =
        localStorage.getItem("access_token") || getCookie("access_token");
      if (token) headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPostsTags: builder.query<any, void>({
      query: () => ({ url: "api/v2/admin/post-tags" }),
    }),
    createPostTag: builder.mutation<any, { name: string }>({
      query: (payload) => ({
        url: "api/v2/admin/post-tags",
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            tagsApi.util.updateQueryData("getPostsTags", undefined, (draft) => {
              if (draft?.data && Array.isArray(draft.data)) {
                draft.data.push(data.data);
              } else {
                console.warn("Draft data is not an array:", draft.data);
              }
            }),
          );
        } catch (error) {
          console.error("Mutation error:", error); // Debugging statement
        }
      },
    }),
  }),
});

export const {
  useGetPostsTagsQuery: useGetPostsTags,
  useCreatePostTagMutation: useCreatePostTag,
} = tagsApi;
