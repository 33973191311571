import React, { useContext } from "react";

import FileName from "./FileName";
import MaterialIcon from "./MaterialIcon";
import { ProcessStepContext } from "./ProcessStep";
import {
  useDownloadOriginalFiles,
  IDownloadableFile,
} from "@hooks/useDownloadOriginalFiles";

const StepAttachments = () => {
  const {
    step: { files, documents },
  } = useContext(ProcessStepContext);

  const attachments = [...(files || []), ...(documents || [])];
  const { downloadMultipleOriginalFiles } = useDownloadOriginalFiles();

  const handleDownloadAll = () => {
    const filesToDownload = attachments.map((at) => {
      const { id } = at;

      return {
        id,
      };
    });
    downloadMultipleOriginalFiles(filesToDownload);
  };

  const handleDownloadFile = (file: IDownloadableFile) => {
    downloadMultipleOriginalFiles([file]);
  };

  const renderAttachments = attachments.map((attachment) => {
    const { id, original_name: originalName, name, type, file } = attachment;
    const attachmentName =
      name ||
      originalName ||
      file?.original_name?.substring(0, file?.original_name.lastIndexOf(".")) ||
      "";
    const attachmentType =
      type ||
      file?.original_name?.split(".")[
        file.original_name.split(".").length - 1
      ] ||
      "";

    return (
      <div key={id} className="m-3 d-flex justify-content-between">
        <FileName name={attachmentName} type={attachmentType} />
        <MaterialIcon
          color="o-cl-grey-200 o-cursor-pointer"
          icon="download"
          size={24}
          onClick={() =>
            handleDownloadFile({
              id: attachment.id,
            })
          }
        />
      </div>
    );
  });

  return (
    <div className="">
      {!!attachments.length && (
        <div>
          <span className="o-ft-sm-700 o-cl-grey-100">Attachments:</span>

          <button
            type="button"
            onClick={handleDownloadAll}
            className="c-process-step__download-button o-cursor d-flex align-items-center"
          >
            <span className="c-process-step__download-button-text o-ft-xs-600 o-cl-grey-100">
              Download all
            </span>
            <MaterialIcon color="o-cl-grey-100" icon="download" size={24} />
          </button>

          <div className="c-step-attachments">
            <div className="c-step-attachments__section">
              <div className="c-step-attachments__content">
                {renderAttachments}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepAttachments;
