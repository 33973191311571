/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useId } from "react";
import styled from "styled-components";
import { ChipContainer, Chip } from "@views/Admin/Posts/styled";
import TruncateText from "@components/TruncateText";

interface ICard {
  imgSrc: string;
  imgCaption?: string;
  date: string;
  title: string;
  tags?: string[];
}

const CardContainer = styled.div`
  height: 231px;
  border-radius: 2.895px;
  background: #fff;
  box-shadow: 0px 0px 14.36px 0px rgba(0, 0, 0, 0.21);
`;
const TextContainer = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ImageContainer = styled.div`
  width: 100%;
  img {
    min-height: 110px;
    max-height: 110px;
    width: 100%;
    object-fit: cover;
  }
`;

const TagContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  max-height: 2.5rem; /* Limita la altura máxima del contenedor */
  overflow: hidden;
  position: relative;
`;

const Card: React.FC<ICard> = ({ imgSrc, imgCaption, date, title, tags }) => {
  const [visibleTags, setVisibleTags] = React.useState<string[]>([]);
  const [hiddenTagCount, setHiddenTagCount] = React.useState(0);
  const tagContainerRef = React.useRef<HTMLDivElement>(null);
  const cardId = useId();

  React.useEffect(() => {
    if (tagContainerRef.current && tags?.length) {
      const containerWidth = tagContainerRef.current.offsetWidth;
      let currentWidth = 0;
      const visible: string[] = [];
      let hiddenCount = 0;

      tags.forEach((tag) => {
        const tagWidth = 66; // Aproximado del tamaño de cada tag
        if (currentWidth + tagWidth <= containerWidth) {
          visible.push(tag);
          currentWidth += tagWidth + 8;
        } else {
          hiddenCount += 1;
        }
      });

      setVisibleTags(visible);
      setHiddenTagCount(hiddenCount);
    }
  }, [tags]);

  return (
    <CardContainer>
      <ImageContainer>
        <img src={imgSrc} alt={`${imgCaption}`} />
      </ImageContainer>
      <TextContainer>
        <div className="o-cl-grey-200 o-ft-xs-600">{date}</div>
        <div
          className="o-cl-grey-100 o-ft-700 text-break"
          id={`card_${cardId}`}
        >
          <TruncateText texto={title} maxLines={1} />
        </div>
        <TagContainer ref={tagContainerRef}>
          {visibleTags.map((tag, index) => (
            <ChipContainer key={`${tag}_${index}`}>
              <Chip>{tag}</Chip>
            </ChipContainer>
          ))}
          {hiddenTagCount > 0 && (
            <ChipContainer>
              <Chip>+</Chip>
            </ChipContainer>
          )}
        </TagContainer>
      </TextContainer>
    </CardContainer>
  );
};

export default Card;
