/* eslint-disable no-nested-ternary */
import Header from "@views/Section/shared/Header";
import { Outlet, useLocation, useParams } from "react-router-dom";
import React from "react";

const ArticleLayout = () => {
  const location = useLocation();
  const { postId } = useParams();
  const baseBreadcrumbs = [
    {
      name: "Communications",
      id: 0,
      route: "/admin/post",
    },
    {
      name: "All Posts",
      id: 1,
      route: "/admin/post",
    },
  ];

  // Definir el tercer breadcrumb basado en la ruta actual
  const dynamicBreadcrumb = {
    name: location.pathname.includes("create")
      ? "Create Post"
      : location.pathname.includes("preview")
        ? "Preview Post"
        : location.pathname.includes("edit")
          ? "Edit Post"
          : postId
            ? "Preview"
            : "", // Default empty string
    route: location.pathname,
    id: 2,
  };

  // Condicional para incluir el tercer breadcrumb solo si tiene un nombre válido
  const breadcrumb = dynamicBreadcrumb.name
    ? [...baseBreadcrumbs, dynamicBreadcrumb]
    : baseBreadcrumbs;
  return (
    <div className="o-admin-screen-container">
      <Header
        isLoading={false}
        actions={[]}
        title="All posts"
        withGoBack={breadcrumb.length > 2}
        breadcrumb={breadcrumb}
      />
      <Outlet />
    </div>
  );
};

export default ArticleLayout;
