/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useReducer, useRef } from "react";
import {
  useGetAllAdminPosts,
  IPost,
  useDeletePost,
  useClonePost,
  IAllPosts,
  useGetAdminFeaturedPost,
  postsApi,
} from "@redux/posts/api";
import { Table } from "react-bootstrap";
import MaterialIcon from "@components/MaterialIcon";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ActionModal from "@components/ActionModal";
import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import { IFilterDropdownItem } from "@components/filters/FilterDropdownList";
import { colors } from "@theme/colors";
import { formatDate } from "@helpers/formatDate";
import Skeleton from "react-loading-skeleton";
import useAppDispatch from "@hooks/useAppDispatch";
import { restartDraftPost } from "@redux/posts/slice";
import NoResult from "@components/NoResult";
import { TagsPopUp } from "@components/TagsPopUp";
import { createToast } from "@helpers/createToast";
import { threeDotsText } from "@helpers/threeDotsText";
import useAppSelector from "@hooks/useAppSelector";

const Buton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  vertical-align: middle;
`;

const TH = styled.th<{ withIcon?: boolean }>(
  ({ withIcon }) => `
  vertical-align: middle;
  width: ${withIcon ? "20px" : "auto"};
  color: ${colors.grey100};
  font-weight: 400;
  font-size: 16px;
`,
);

const StyledTR = styled.tr``;

const TableContainer = styled.div`
  min-height: 450px;
  max-height: 450px;
  overflow-y: auto;
`;

const StyledThead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

const StyledTBody = styled.tbody`
  border-top: none !important;
  overflow-y: auto;
  max-height: 450px;
`;

const PostName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

enum FilterActions {
  IS_FEATURED = "IS_FEATURED",
  SORT_POSTS = "SORT_POSTS",
}

type FilterAction = {
  type: FilterActions;
  payload?: number | boolean | any;
};

const ListPost = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [postToClone, setPostToClone] = useState<{
    id: string;
    title: string;
  }>();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tbodyRef = useRef<any>(null);
  const initialReducer = {};

  const filterReducer = (state: IAllPosts, action: FilterAction) => {
    switch (action.type) {
      case FilterActions.SORT_POSTS:
        return {
          ...state,
          orderBy: action.payload.orderBy,
          orderDirection: action.payload.orderDirection,
        };
      default:
        return state;
    }
  };
  const [filterState, filterDispatch] = useReducer(
    filterReducer,
    initialReducer,
  );
  const {
    data: postsData,
    isFetching,
    // refetch,
  } = useGetAllAdminPosts({
    page,
    ...filterState,
  });
  const { data: featuredPost, isFetching: isFetchingFeaturedPost } =
    useGetAdminFeaturedPost();
  const [deletePost, status] = useDeletePost();
  const [clonePost, { isLoading: isCloningPost }] = useClonePost();
  const [isFeaturePostChecked, setIsFeaturePostChecked] = useState(false);
  const [postToDelete, setPostToDelete] = useState<string | undefined>(
    undefined,
  );
  const { data: user } = useAppSelector((state) => state.user);

  const [posts, setPosts] = useState<IPost[]>([]);

  const sortPostBy = (orderBy: string) => {
    setPosts([]);
    setPage(1);
    filterDispatch({
      type: FilterActions.SORT_POSTS,
      payload: {
        orderBy,
        orderDirection: filterState.orderDirection === "asc" ? "desc" : "asc",
      },
    });
  };

  const onChangeCheckbox = () => {
    setIsFeaturePostChecked(!isFeaturePostChecked);
  };

  const checkForExtraSpace = () => {
    const tableContainer = tableContainerRef.current;
    if (
      tableContainer &&
      tableContainer.offsetHeight < 450 &&
      posts.length > 0 &&
      postsData?.meta.total > posts.length
    ) {
      setPage(page + 1);
    }
  };
  useEffect(() => {
    if (postsData && !isFeaturePostChecked) {
      checkForExtraSpace();
      setPosts([...posts, ...postsData.data]);
      return;
    }

    if (isFeaturePostChecked) {
      setPosts(postsData?.data);
    }
  }, [postsData]);

  useEffect(() => {
    dispatch(restartDraftPost());
  }, []);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && postsData.meta.total > posts.length && !isFetching) {
      setPage(page + 1);
    }
  };

  const postsToRender = isFeaturePostChecked ? featuredPost.data : posts;

  return (
    <>
      <ActionModal
        isLoading={status.isLoading}
        show={postToDelete !== undefined}
        title="Are you sure you want to delete this post?"
        subtitle="Remember that you won’t be able to recover it again."
        buttonLeftText="Cancel"
        buttonRightText="Continue"
        onClickLeftButton={() => setPostToDelete(undefined)}
        onClickRightButton={() => {
          if (postToDelete) {
            deletePost({ id: postToDelete })
              .unwrap()
              .then(() => {
                setPosts(posts.filter((post) => post.id !== postToDelete));
                setPostToDelete(undefined);
              });
          }
        }}
      />
      <ActionModal
        isLoading={isCloningPost}
        show={!!postToClone}
        title="Are you sure you want to clone this post?"
        buttonLeftText="Cancel"
        buttonRightText="Continue"
        onClickLeftButton={() => setPostToClone(undefined)}
        onClickRightButton={() => {
          if (postToClone) {
            clonePost({ id: postToClone.id })
              .unwrap()
              .then(() => {
                const text = `Post Cloned <br/>"${threeDotsText(postToClone?.title ?? "", 30)}" post has been cloned successfully.`;
                createToast(text, "success", dispatch);
                dispatch(postsApi.util.resetApiState());
                setPosts([]);
                setPage(1);
                setPostToClone(undefined);
              });
          }
        }}
      />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Button onClick={() => navigate("create")} value="Add Post" />
        <Checkbox
          item={{ id: 0, name: "View Featured Posts" } as IFilterDropdownItem}
          onChange={() => onChangeCheckbox()}
          checked={isFeaturePostChecked}
          showName
          size="large"
          textPosition="left"
        />
      </div>
      {isFeaturePostChecked && postsToRender.length === 0 ? (
        <NoResult />
      ) : (
        <TableContainer ref={tableContainerRef} onScroll={handleScroll}>
          <Table hover>
            <StyledThead>
              <StyledTR>
                <TH withIcon>
                  <Buton type="button" onClick={() => sortPostBy("name")}>
                    <MaterialIcon icon="swap_vert" />
                  </Buton>
                </TH>
                <TH>Title</TH>
                <TH withIcon>
                  <Buton type="button" onClick={() => sortPostBy("created_at")}>
                    <MaterialIcon icon="swap_vert" />
                  </Buton>
                </TH>
                <TH>Date</TH>
                <TH withIcon>
                  <Buton type="button" onClick={() => sortPostBy("author")}>
                    <MaterialIcon icon="swap_vert" />
                  </Buton>
                </TH>
                <TH>Author</TH>
                <TH>Tags</TH>
                <TH withIcon>
                  <Buton
                    type="button"
                    onClick={() => sortPostBy("is_published")}
                  >
                    <MaterialIcon icon="swap_vert" />
                  </Buton>
                </TH>
                <TH>Status</TH>
                <TH>Actions</TH>
              </StyledTR>
            </StyledThead>
            <StyledTBody ref={tbodyRef}>
              {postsToRender.map((post: IPost) => (
                <StyledTR key={post.id}>
                  <td />
                  <td
                    style={{
                      maxWidth: "230px",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <MaterialIcon
                        icon="newspaper"
                        color={
                          post.is_featured ? "o-cl-brand-primary" : undefined
                        }
                      />
                      <PostName className="mx-2 o-ft-sm-700 o-cl-grey-100">
                        {post.name}
                      </PostName>{" "}
                      {post.is_featured && (
                        <span className="o-cl-brand-primary o-ft-sm-700">
                          {" "}
                          Featured
                        </span>
                      )}
                    </div>
                  </td>
                  <td />
                  <td className="o-ft-sm o-cl-grey-200">
                    {formatDate(post.created_at)}
                  </td>
                  <td />
                  <td className="o-ft-sm o-cl-grey-200">
                    {post.is_author_visible ? post.author.name : ""}
                  </td>
                  <td
                    className="o-ft-sm o-cl-grey-200"
                    style={{
                      maxWidth: "250px",
                    }}
                  >
                    <TagsPopUp tags={post.post_tags} />
                  </td>
                  <td />
                  <td className="o-ft-sm o-cl-grey-200">
                    {post.is_published ? "PUBLISHED" : "DRAFT"}
                  </td>
                  <td>
                    <div className="d-flex gap-2">
                      <Buton type="button" onClick={() => navigate(post.id)}>
                        <MaterialIcon icon="visibility" color="o-cl-grey-100" />
                      </Buton>
                      {user?.permissions.Document?.includes("write") && (
                        <>
                          <Buton
                            type="button"
                            onClick={() => navigate(`${post.id}/edit`)}
                          >
                            <MaterialIcon icon="edit" color="o-cl-grey-100" />
                          </Buton>
                          <Buton
                            type="button"
                            onClick={() =>
                              setPostToClone({ id: post.id, title: post.name })
                            }
                          >
                            <MaterialIcon
                              icon="copy_all"
                              color="o-cl-grey-100"
                            />
                          </Buton>
                          <Buton
                            type="button"
                            onClick={() => setPostToDelete(post.id)}
                          >
                            <MaterialIcon icon="delete" color="o-cl-grey-100" />
                          </Buton>
                        </>
                      )}
                    </div>
                  </td>
                </StyledTR>
              ))}
              {(isFetching || isFetchingFeaturedPost) &&
                [1, 2, 3].map(() => (
                  <StyledTR>
                    <td />
                    <td>
                      <Skeleton width={100} />
                    </td>
                    <td />
                    <td>
                      <Skeleton width={100} />
                    </td>
                    <td />
                    <td>
                      <Skeleton width={100} />
                    </td>
                    <td>
                      <Skeleton width={100} />
                    </td>
                    <td />
                    <td>
                      <Skeleton width={100} />
                    </td>
                    <td>
                      <Skeleton width={100} />
                    </td>
                  </StyledTR>
                ))}
            </StyledTBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ListPost;
