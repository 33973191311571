import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import AdminResultsView from "@views/AdminResults";
import All from "@views/Forms/shared/All";
import PostLayout from "@views/Admin/Posts/Layout";
import Create from "@views/Forms/shared/Create";
import ViewPostForm from "@views/Admin/Posts/ViewPostForm";
import ErrorView from "@views/ErrorView";
import FileManagementView from "@views/FileManagement";
import FormsLayout from "@views/Forms/Layout";
import ListPost from "@views/Admin/Posts/List";
import LoadingView from "@views/LoadingView";
import NotionPage from "@views/Notion";
import ProcessReview from "@views/Admin/ProcessReview/ProcessReview";
import ProcessReviewLayout from "@views/Admin/ProcessReview/Layout";
import { ShowDocument } from "@views/Documents";
import ProcessView from "@views/Process";
import Trash from "@views/Trash";
import VersionHistory from "@views/VersionHistory";
import { getFoldersId } from "@redux/files/thunks/fileThunk";
import { getLoggedUser } from "@redux/users/thunks/userThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import ShowPost from "@views/Admin/Posts/Show";
import PreviewPost from "@views/Admin/Posts/Preview";
// Forms

const AdminRouter = () => {
  const {
    file: { foldersId, foldersPending },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  localStorage.setItem("current_website", "admin");
  useEffect(() => {
    dispatch(getLoggedUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFoldersId());
  }, [dispatch]);

  const renderNotFoundRoute = (
    <Route path="*" element={<ErrorView statusCode={404} />} />
  );

  const renderRoutes = (
    <Route path="/">
      <Route path="knowledge" element={<NotionPage isAdmin />}>
        <Route path=":id" />
      </Route>

      <Route path="/documents/:id" element={<ShowDocument />} />
      {/* admin/form/{id}/edit */}
      <Route path="/all-forms" element={<FormsLayout />}>
        <Route index element={<All />} />
        <Route path="add-form" element={<Create />} />
        <Route path="edit-form" element={<Create />} />
      </Route>
      <Route path="/process-review/review" element={<ProcessReviewLayout />}>
        <Route index element={<ProcessReview />} />
      </Route>
      <Route path="file-management">
        <Route path="" element={<FileManagementView />} />
        <Route path="trash" element={<Trash />} />
        <Route path="results" element={<AdminResultsView />} />
        <Route path="history/:documentId" element={<VersionHistory />} />
        {foldersId.length &&
          foldersId.map((id) => (
            <Route key={id} path={`${id}`} element={<FileManagementView />} />
          ))}
      </Route>
      <Route path="post" element={<PostLayout />}>
        <Route path="" element={<ListPost />} />
        <Route path=":postId" element={<ShowPost />} />
        <Route path="preview" element={<PreviewPost />} />
        <Route path="create" element={<ViewPostForm />} />
        <Route path=":postId/edit" element={<ViewPostForm />} />
      </Route>
      <Route path="process/create" element={<ProcessView />} />
      <Route path="process/:process/edit" element={<ProcessView />} />
    </Route>
  );

  const loadingView = <Route path="*" element={<LoadingView />} />;

  return (
    <div className="d-flex o-admin-scroll">
      <div className="w-100">
        <Routes>
          {foldersPending ? (
            loadingView
          ) : (
            <>
              {renderNotFoundRoute}
              {renderRoutes}
            </>
          )}
        </Routes>
      </div>
    </div>
  );
};

export default AdminRouter;
