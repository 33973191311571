/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IPost } from "./api";
import { PostFormData } from "@views/Admin/Posts/ViewPostForm";

interface IPostSlice {
  post: IPost | PostFormData;
}

const initialState: IPostSlice = {
  post: {
    name: "",
    subtitle: "",
    content: "",
    image_caption: null,
    is_published: false,
    is_featured: false,
    is_author_visible: true,
    coverImage: null,
    post_tags: [],
  },
};

export const post = createSlice({
  name: "post",
  initialState,
  reducers: {
    restartDraftPost: () => initialState,
    setDraftPost: (
      state,
      action: PayloadAction<{ data: IPost | PostFormData }>,
    ) => {
      state.post = action.payload.data;
    },
  },
});

export const { setDraftPost, restartDraftPost } = post.actions;
export default post.reducer;
