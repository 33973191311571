import React from "react";

export interface ITag {
  id: number | string;
  name: string;
}
interface ITagsPopUpProps {
  tags: ITag[];
}

export const TagsPopUp = ({ tags }: ITagsPopUpProps) => {
  return (
    <div className="c-tags-pop-up position-relative">
      <span className="c-tags-pop-up__tags o-ft-sm-400 o-cl-grey-200">
        {tags.map((tag: ITag) => tag.name).join(", ")}
      </span>
      <div className="c-tags-pop-up__list position-absolute">
        {tags.map(({ id, name }) => (
          <span
            key={id}
            className="c-tags-pop-up__item o-cl-grey-100 o-ft-sm-400"
          >
            #{name}
          </span>
        ))}
      </div>
    </div>
  );
};
