/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from "react";
import { IPost, useGetAllPosts } from "@redux/posts/api";
import { colors } from "@theme/colors";
import { Spinner } from "react-activity";
import Card from "@components/Card/Card";
import { formatDate } from "@helpers/formatDate";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 3rem;
`;

const PostContainer = styled.div`
  flex-basis: calc(25% - 15px);
  @media (max-width: 1024px) {
    flex-basis: calc(33.33% - 15px);
  }

  /* Media query para teléfonos móviles */
  @media (max-width: 768px) {
    flex-basis: calc(50% - 10px);
  }
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
`;

const List = () => {
  const [page, setPage] = useState(1);

  const { data: postData, isFetching } = useGetAllPosts({
    page,
  });

  const [listPosts, setListPosts] = useState<IPost[]>([]);

  const observerRef = useRef<HTMLDivElement | null>(null);

  const handleObserver = (entries: IntersectionObserverEntry[]) => {
    const target = entries[0];
    if (
      target.isIntersecting &&
      !isFetching &&
      postData?.meta.current_page < postData?.meta.last_page
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [isFetching]);

  useEffect(() => {
    if (postData) {
      setListPosts([...listPosts, ...postData.data]);
    }
  }, [postData]);

  return (
    <div>
      <h1 className="o-cl-grey-100 o-ft-3xl-600">Communications</h1>
      <hr style={{ width: "100%" }} />

      <PostsContainer>
        {listPosts?.map((post: IPost) => (
          <PostContainer>
            <StyledLink to={post.id}>
              <Card
                imgSrc={post.file.url || ""}
                imgCaption={post.name}
                title={post.name}
                date={formatDate(post.created_at)}
                tags={post.post_tags.map((tag) => tag.name)}
              />
            </StyledLink>
          </PostContainer>
        ))}
      </PostsContainer>
      {isFetching && (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      )}
      <div
        ref={observerRef}
        style={{ height: "20px", background: "transparent" }}
      />
    </div>
  );
};

export default List;
