import useAppSelector from "@hooks/useAppSelector";
import { TTag } from "../app/types/Tag.type";
import React from "react";
import { useGetTags, useCreateTag } from "@redux/documents/api";

interface ITagResultsProps {
  onClick: (tag: TTag) => void;
}

export const TagResults = ({ onClick }: ITagResultsProps) => {
  const [createTag, { isLoading }] = useCreateTag();
  const { data: tags } = useGetTags();
  const {
    tags: { currentTagValue },
  } = useAppSelector((state) => state);

  const includesString = (o: TTag) =>
    o.name.toLowerCase().includes(currentTagValue.toLowerCase()) || false;

  const filteredData = tags.data.filter((tag: TTag) => includesString(tag));

  return (
    <div className="c-tag-results o-bg-white position-absolute">
      <ul className="c-tag-results__list">
        <li
          onClick={() =>
            createTag({ name: currentTagValue })
              .unwrap()
              .then((res) => onClick(res.data))
          }
          className="c-tag-results__item o-cl-grey-100 o-ft-sm-400"
          aria-hidden
          style={{
            pointerEvents: isLoading ? "none" : "auto",
            opacity: isLoading ? 0.5 : 1,
          }}
        >
          {`Create tag: #${currentTagValue}`}
        </li>
        {filteredData.map(({ id, name }: TTag) => {
          const tagName = name.charAt(0).toUpperCase() + name.slice(1);
          return (
            <li
              onClick={() => onClick({ id, name })}
              key={id}
              className="c-tag-results__item o-cl-grey-100 o-ft-sm-400"
              aria-hidden
            >{`#${tagName}`}</li>
          );
        })}
      </ul>
    </div>
  );
};
