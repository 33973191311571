import styled from "styled-components";
import { colors } from "@theme/colors";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 1048px;
  width: 100%;
`;

export const ChipContainer = styled.div<{ $backgroundColor?: string }>(
  ({ $backgroundColor = colors.grey300 }) => `
  background-color: ${$backgroundColor};
  height: 32px;
  display: flex;
  padding: 6px 11px;
  align-items: center;
  border-radius: 4px;
`,
);

export const Chip = styled.div<{ $color?: string }>(
  ({ $color = colors.grey100 }) => `
  font-size: 12px;
  font-weight: 600;
  color: ${$color};
`,
);

export const Content = styled.div`
  img {
    max-height: 305px;
    width: 100%;
    object-fit: cover;
  }
`;

export const ImageContainer = styled.div`
  max-width: 1048px;
  width: 100%;

  img {
    width: 100%;
    max-height: 441px;
    object-fit: cover;
  }
`;

export const PostContainer = styled.div<{ $maxWidth?: number }>(
  ({ $maxWidth = 1048 }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: ${$maxWidth}px;
  width: 100%;
  gap: 1rem;
`,
);
