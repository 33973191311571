import { whichProvider } from "@helpers/whichProvider";

export default class TagController {
  static add(tags: Array<string>, document_id: number) {
    return whichProvider().request(
      "post",
      `api/admin/documents/${document_id}/tags`,
      JSON.stringify({ tags }),
    );
  }
}
