/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { IPost, useGetFeaturedPost } from "@redux/posts/api";
import { formatDateMMMMddYYYY } from "@helpers/formatDate";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

interface IFeaturedPostWidgetProps {}

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ImageContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: 185px;
    object-fit: cover;
  }
`;

const PostName = styled.span`
  max-height: 100px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Número máximo de líneas antes de truncar */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
`;

const FeaturedPostWidget: React.FC<IFeaturedPostWidgetProps> = () => {
  const { data: featuredPost } = useGetFeaturedPost();
  const settings = {
    dots: true,
    infinite: featuredPost?.data.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const sliderRef = useRef<Slider | null>(null);

  useEffect(() => {
    sliderRef?.current?.slickPlay();
  }, []);

  const navigate = useNavigate();
  return (
    <Slider {...settings} ref={sliderRef}>
      {featuredPost?.data.map((post: IPost) => (
        <div className="d-flex flex-column gap-2">
          <ImageContainer>
            <img
              src={post.file?.url || "https://picsum.photos/600/600"}
              alt={post.name}
            />
          </ImageContainer>
          <PostContainer>
            <div>
              <span className="o-ft-sm-400 o-cl-grey-200">
                {formatDateMMMMddYYYY(post.created_at)}
              </span>
            </div>
            <div>
              <PostName className="o-ft-700 o-cl-grey-100">
                {post.name}
              </PostName>
            </div>
            <div id={`content_${post.id}`} className="o-ft-xs-400">
              {post.subtitle}
            </div>
          </PostContainer>
          <div>
            <Button
              value="Read More"
              variant="link"
              onClick={() => navigate(`/posts/${post.id}`)}
              icon={{
                icon: "chevron_right",
                position: "right",
                size: 18,
              }}
            />
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default FeaturedPostWidget;
