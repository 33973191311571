/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useGetPost } from "@redux/posts/api";
import RenderPost from "@sections/Posts/RenderPost";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import { PostContainer } from "@views/Admin/Posts/styled";
import ButtonUI from "@components/Button";
import styled from "styled-components";
import { createToast } from "@helpers/createToast";
import useAppDispatch from "@hooks/useAppDispatch";
import ErrorView from "@views/ErrorView";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const Show = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id } = useParams();
  const {
    data: post,
    isFetching,
    error,
  } = useGetPost({
    id,
  });

  function isFetchBaseQueryError(
    errorFetchBaseQuery: any,
  ): errorFetchBaseQuery is FetchBaseQueryError {
    return (
      errorFetchBaseQuery &&
      typeof errorFetchBaseQuery === "object" &&
      "status" in errorFetchBaseQuery
    );
  }

  if (error && isFetchBaseQueryError(error) && error.status === 404) {
    return (
      <div>
        <ErrorView statusCode={404} />
      </div>
    );
  }
  return (
    <div className="d-flex gap-2">
      {isFetching ? (
        <div className="d-flex justify-content-center mt-5 w-100">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      ) : (
        <div className="d-flex w-100">
          <PostContainer $maxWidth={786}>
            <RenderPost post={post.data} />
          </PostContainer>
          <ButtonsContainer>
            <ButtonUI
              value="Go Back"
              icon={{
                icon: "arrow_back_ios",
                size: 12,
                position: "right",
              }}
              onClick={() => navigate(-1)}
            />
            <hr className="w-50" />
            <div className="d-flex flex-column gap-2 align-items-start w-50">
              <span className="o-ft-sm-400 o-cl-grey-100">Share Post: </span>
              <ButtonUI
                size="small"
                value="Copy link"
                icon={{
                  icon: "insert_link",
                  size: 12,
                  position: "right",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}${location.pathname}`,
                  );
                  createToast("Copied", "success", dispatch);
                }}
              />
            </div>
          </ButtonsContainer>
        </div>
      )}
    </div>
  );
};
export default Show;
