/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCookie } from "@api/AdminHttpProvider";
import { ITag } from "@components/TagsPopUp";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

export interface IPostPayload {
  id?: string;
  name: string;
  subtitle: string;
  content: string;
  is_published: boolean;
  is_featured: boolean;
  is_author_visible: boolean;
  image_caption: string | null;
  post_tags: string[];
  file?: {
    original_name?: string;
    extension?: string;
    mime_type?: string;
    size?: number;
  };
}

export interface IPostFile {
  original_name: string;
  mime_type: string;
  extension: string;
  size: number;
  url?: string;
  id?: string;
  name?: string;
}

interface IAvatar {
  id: number;
  url: string;
}
interface IUser {
  name: string;
  avatar_file: IAvatar;
}
export interface IPost {
  id: string;
  author: IUser;
  content: string;
  created_at: Date;
  is_author_visible: boolean;
  is_featured: boolean;
  is_published: boolean;
  name: string;
  subtitle: string;
  image_caption: string | null;
  post_tags: ITag[];
  file: IPostFile;
  coverImage?: any;
  fileName?: string;
}
interface IPostsData {
  data: IPost[];
}
type IToogleFeaturedPayload = Pick<IPost, "id" | "is_featured">;
type ITooglePublishedPayload = Pick<IPost, "id" | "is_published">;

// Crear una nueva interfaz excluyendo campos no necesarios para PUT
type PostUpdatePayload = Omit<
  IPostPayload,
  "is_author_visible" | "image_caption" | "file"
> & {
  file_id?: string; // Agregar el campo file_id que es necesario para PUT
};

export interface IAllPosts {
  page?: number;
  isFeatured?: boolean;
  orderBy?: string;
  orderDirection?: "asc" | "desc";
}

export const postsApi = createApi({
  reducerPath: "postsApi",
  tagTypes: ["Posts", "Post", "Featured_Admin_Posts", "Featured_Posts"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token =
        localStorage.getItem("access_token") || getCookie("access_token");
      if (token) headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllPosts: builder.query<any, IAllPosts>({
      query: ({
        page = 1,
        isFeatured,
        orderBy = " created_at",
        orderDirection = "desc",
      }) => {
        const queryParams = [
          `page=${page}`,
          `perPage=12`,
          isFeatured ? `filters[is_featured]=1` : "",
          orderBy ? `orderBy=${orderBy}` : "",
          orderDirection ? `orderDirection=${orderDirection}` : "",
        ];
        return `api/v2/posts?${queryParams.filter(Boolean).join("&")}`;
      },
    }),
    getAllAdminPosts: builder.query<any, IAllPosts>({
      query: ({ page = 1, isFeatured, orderBy, orderDirection }) => {
        const queryParams = [
          `page=${page}`,
          `perPage=10`,
          isFeatured ? `filters[is_featured]=1` : "",
          orderBy ? `orderBy=${orderBy}` : "",
          orderDirection ? `orderDirection=${orderDirection}` : "",
        ];
        return `api/v2/admin/posts?${queryParams.filter(Boolean).join("&")}`;
      },
      providesTags: ["Posts"],
    }),
    getAdminFeaturedPost: builder.query<any, void>({
      query: () => ({ url: `api/v2/admin/posts?filters[is_featured]=1` }),
      providesTags: ["Featured_Admin_Posts"],
    }),
    getFeaturedPost: builder.query<any, void>({
      query: () => ({ url: `api/v2/posts?filters[is_featured]=1` }),
      providesTags: ["Featured_Posts"],
    }),
    getPost: builder.query<any, { id?: string }>({
      query: ({ id }) => ({ url: `api/v2/posts/${id}` }),
      providesTags: (result: any, error: any, arg: any) =>
        result
          ? [{ type: "Post", id: result.data.id }]
          : [{ type: "Post", id: arg.id }],
    }),
    getAdminPost: builder.query<any, { id?: string }>({
      query: ({ id }) => ({ url: `api/v2/admin/posts/${id}` }),
    }),
    createPost: builder.mutation<any, any>({
      query: (payload) => ({
        url: `api/v2/admin/posts`,
        method: "POST",
        body: payload,
      }),
    }),
    putPost: builder.mutation<any, PostUpdatePayload>({
      query: (payload) => ({
        url: `api/v2/admin/posts/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    toogleFeaturedPost: builder.mutation<any, IToogleFeaturedPayload>({
      query: (payload) => {
        return {
          url: `api/v2/admin/posts/${payload.id}`,
          method: "PATCH",
          body: {
            is_featured: payload.is_featured,
          },
        };
      },
      invalidatesTags: ["Posts"],
    }),
    tooglePublishedPost: builder.mutation<any, ITooglePublishedPayload>({
      query: (payload) => {
        return {
          url: `api/v2/admin/posts/${payload.id}`,
          method: "PATCH",
          body: {
            is_published: payload.is_published,
          },
        };
      },
      invalidatesTags: ["Posts"],
      async onQueryStarted({ id, is_published }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          postsApi.util.updateQueryData("getAdminPost", { id }, (draft) => {
            const post = draft.data;
            post.is_published = is_published;
          }),
        );
      },
    }),
    deletePost: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `api/v2/admin/posts/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          postsApi.util.updateQueryData(
            "getAdminFeaturedPost",
            undefined,
            (draft) => {
              draft.data = draft.data.filter((post: IPost) => post.id !== id);
            },
          ),
        );
      },
    }),
    clonePost: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `api/v2/admin/posts/${id}/clone`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetAllPostsQuery: useGetAllPosts,
  useGetAllAdminPostsQuery: useGetAllAdminPosts,
  useGetPostQuery: useGetPost,
  useGetAdminPostQuery: useGetAdminPost,
  useCreatePostMutation: useCreatePost,
  usePutPostMutation: usePutPost,
  useDeletePostMutation: useDeletePost,
  useClonePostMutation: useClonePost,
  useTooglePublishedPostMutation: useTooglePublishedPost,
  useToogleFeaturedPostMutation: useToogleFeaturedPost,
  useGetAdminFeaturedPostQuery: useGetAdminFeaturedPost,
  useGetFeaturedPostQuery: useGetFeaturedPost,
} = postsApi;
