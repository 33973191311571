import { Tag } from "@components/Tag";
import { TTag } from "@models/Tag.type";
import React from "react";

interface ITagListProps {
  list: TTag[];
  onClick: (tag: TTag, documentId: number) => void;
  documentId: number;
}

export const TagList = ({ list, onClick, documentId }: ITagListProps) => {
  const mapList = list.map((tag: TTag) => (
    <div key={tag.id} className="mb-2 me-2">
      <Tag {...{ tag, documentId, onClick }} />
    </div>
  ));

  return (
    <div className="c-tag-list d-flex flex-wrap position-relative">
      {mapList}
    </div>
  );
};
