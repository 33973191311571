/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import React, { useCallback, useEffect, useState } from "react";
import {
  useGetIsSigner,
  useGetSignedInfo,
  usePostAddSigner,
} from "@redux/nda-modal/api";

import ActivitySection from "@components/ActivitySection";
import HeaderModule from "@components/HeaderModule";
import NdaModal from "@components/modals/NdaModal";
import Onboarding from "@components/Onboarding";
import { SectionModuleItem } from "@components/SectionModuleItem";
import { Spinner } from "react-activity";
import { TIcon } from "@components/MaterialIcon";
import classNames from "classnames";
import { colors } from "@theme/colors";
import { isString } from "ramda-adjunct";
import { setLoader } from "@redux/loader/loader.slice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useDocumentSlice } from "../../stores/useDocumentSlice";
import { useGetDashboardSections } from "@redux/sections/api";
import { useNavigate } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";
import { usePinUnpinContext } from "../../providers/PinUnpinProvider";
import { documentsApi } from "@redux/documents/api";
import FeaturedPostWidget from "@components/FeaturedPostWidget";
import { useGetFeaturedPost } from "@redux/posts/api";
import DateHourWidget from "@components/DateHourWidget";

const DashboardSection: React.FC = () => {
  const { data: featuredPost, isFetching } = useGetFeaturedPost();
  const { responsive } = useWindowSize();
  const DateHourWidgetClass = classNames({
    "flex-column-reverse": responsive.xs && !responsive.lg,
    "flex-row": responsive.lg,
  });
  const { data, pending } = useAppSelector((state) => state.user);

  const {
    data: dashboardItems,
    isLoading,
    refetch,
  } = useGetDashboardSections();

  const { tooglePinDashboard } = usePinUnpinContext();
  const dispatch = useAppDispatch();

  const { data: signedData } = useGetSignedInfo(undefined, {
    skip: process.env.REACT_APP_ENABLE_IRONCLAD_MODULE === "false",
  });
  const { data: isSigner } = useGetIsSigner(undefined, {
    skip: process.env.REACT_APP_ENABLE_IRONCLAD_MODULE === "false",
  });
  const [addSigner] = usePostAddSigner();

  const onboarding = localStorage.getItem("onboarding");

  const navigate = useNavigate();

  const { lastFile, setLastFile } = useDocumentSlice((state) => state);

  const screenContainerClass = classNames({
    "pt-4": !responsive.md,
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!data) return;

    const origin = localStorage.getItem("origin");
    const accessToken = localStorage.getItem("access_token");

    if (origin === "manage" && accessToken && data.has_manage_access) {
      localStorage.removeItem("origin");
      window.location.href = `${process.env.REACT_APP_BACK_BASE_URL}auth/login-from-platform?access_token=${accessToken}`;
      dispatch(setLoader(true));
    }
  }, [dispatch, data]);

  const isLogged = localStorage.getItem("access_token");

  useEffect(() => {
    if (!isLogged) navigate("/login");
  }, [isLogged, navigate]);

  const renderItems = dashboardItems?.data?.map((item: any) => {
    const document = {
      id: item.pinned?.path.split("/")[1],
      parent_id: null,
      name: "",
      is_dashboard_pinned: item.pinned || false,
    };
    return (
      <SectionModuleItem
        context="dashboard"
        element={item}
        key={item.id}
        icon={item.icon as TIcon}
        onClick={() => navigate(`/${item.pinned?.path || item.path}`)}
        title={item.pinned?.name || item.name}
        children={[]}
        actionList={[
          {
            icon: "push_pin",
            id: "pin_to_home",
            onClick: () => {
              tooglePinDashboard(document);
              refetch();
              dispatch(
                documentsApi.util.invalidateTags([
                  { type: "documents", id: "LIST" },
                ]),
              );
            },
            text: "Unpin from Home",
            type: "pin",
          },
        ]}
        background={item.background}
      />
    );
  });
  useEffect(() => {
    if (isString(lastFile)) window.open(`/documents/${lastFile}`, "_blank");
    setLastFile(null);
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddSigner = useCallback(async () => {
    try {
      const response = await addSigner();

      if ("error" in response) {
        if ("status" in response.error && response.error.status >= "400") {
          throw new Error("Bad response from server");
        } else {
          throw new Error("Unknown error occurred");
        }
      }

      setShowModal(true);
    } catch (error) {
      console.error("Error in handleAddSigner:", error);
    }
  }, [addSigner]);

  const renderWidget = () =>
    featuredPost?.data.length > 0 ? <FeaturedPostWidget /> : <DateHourWidget />;

  useEffect(() => {
    if (
      process.env.REACT_APP_ENABLE_IRONCLAD_MODULE === "false" ||
      !isSigner ||
      !signedData
    )
      return;

    if (isSigner?.is_signer && !signedData?.signed) {
      setShowModal(true);
      return;
    }

    if (!isSigner?.is_signer) {
      handleAddSigner();
    }
  }, [handleAddSigner, signedData, isSigner]);

  return (
    <>
      {showModal && <NdaModal onClose={handleCloseModal} />}
      <div
        id="dashboardScreen"
        className={`v-dashboard o-screen-container ${screenContainerClass}`}
      >
        {responsive.md && <HeaderModule title="Dashboard" withBreadcrumb />}
        <div className={`d-flex ${DateHourWidgetClass}`}>
          <div className="v-dashboard__sections-container d-flex">
            {isLoading ? (
              <div className="d-flex justify-content-center mt-5 w-100">
                <Spinner
                  className="v-contacts__spinner"
                  color={colors.brandColorSecondary}
                />
              </div>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>{renderItems}</>
            )}
          </div>
          <div className="c-featured-post-widget d-flex flex-column overflow-hidden flex-shrink-0">
            {isFetching ? (
              <div className="d-flex justify-content-center mt-5 flex-column align-items-center gap-4">
                <Spinner
                  className="o-ft-xl o-ft-3xl@md"
                  color={colors.brandColorSecondary}
                />
              </div>
            ) : (
              renderWidget()
            )}
          </div>
        </div>
        <ActivitySection />
      </div>
      {!data?.onboarding && !onboarding && !pending && <Onboarding />}
    </>
  );
};

export default DashboardSection;
