const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getDaySuffix = (day: number) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
export const formatDateDDmmmYYYY = (date: Date) => {
  const dateToParse = new Date(date);
  const formattedDate = `${dateToParse.getDate().toString().padStart(2, "0")} ${shortMonths[dateToParse.getMonth()]} ${dateToParse.getFullYear()}`;
  return Number.isNaN(dateToParse.getTime()) ? "" : formattedDate;
};

export const formatDate = (date: Date) => {
  const dateToParse = new Date(date);
  const monthOfYear = dateToParse.getMonth() + 1;
  const getDayWith2Digits = `${dateToParse.getDate() < 10 ? "0" : ""}${dateToParse.getDate()}`;
  const getMonthWith2Digits = `${monthOfYear < 10 ? "0" : ""}${monthOfYear}`;
  return `${getMonthWith2Digits}/${getDayWith2Digits}/${dateToParse.getFullYear()}`;
};

export const formatDateMMMMddYYYY = (date: Date) => {
  const dateToParse = new Date(date);
  const day = dateToParse.getDate();
  const month = months[dateToParse.getMonth()];
  const year = dateToParse.getFullYear();

  return `${month} ${day}${getDaySuffix(day)}, ${year}`;
};
