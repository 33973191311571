/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from "react";
import AdminHeaderModule from "@components/AdminHeaderModule";
import useAppSelector from "@hooks/useAppSelector";
import { IRow } from "@models/Table.type";
import FileName from "@components/FileName";
import { formatDate } from "@helpers/formatDate";
import Button from "@components/Button";
import useWindowSize from "@hooks/useWindowSize";
import Table from "@components/Table";
import useAppDispatch from "@hooks/useAppDispatch";
import CurrentVersionButton from "@components/CurrentVersionButton";
import _ from "lodash";
import { IFile } from "@models/files/File.type";
import DotsMenu from "@components/DotsMenu";
import { TIcon } from "@components/MaterialIcon";
import ActionModal from "@components/ActionModal";
import {
  getVersionsHistory,
  restoreVersion,
} from "@redux/files/thunks/fileThunk";
import { formatWeight } from "@helpers/formatWeight";
import { formatHour } from "@helpers/formatHour";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";

const VersionHistory = () => {
  const { responsive } = useWindowSize();
  const { documentId } = useParams();
  const {
    file: {
      selectedFiles,
      history: { data: historyData, pending },
    },
  } = useAppSelector((state) => state);
  const {
    trash: { data },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentFileHistory: IFile = JSON.parse(
    localStorage.getItem("current_file_history")!,
  );
  const _data = {
    ...data,
    parent_id: currentFileHistory.parent_id,
    name: "history",
  }; // remove hardcoded name when functionality is ready
  const [showRestoreModal, setShowRestoreModal] = useState<boolean>(false);
  const [versionFileToRestore, setVersionFileToRestore] = useState<any>();

  const firstId: number = historyData[0]?.id;

  const fileStatusButton = (file: IRow): JSX.Element => {
    let buttonComponent = <></>;

    if (_.isEqual(firstId, file?.id)) {
      buttonComponent = <CurrentVersionButton text="Current Version" />;
    } else {
      buttonComponent = (
        <Button
          onClick={() => {
            setVersionFileToRestore(file as IFile);
            setShowRestoreModal(true);
          }}
          className="v-version-history__button"
          value="Restore"
        />
      );
      if (!currentFileHistory.file_id) {
        buttonComponent = <></>;
      }
    }

    return buttonComponent;
  };

  const isFirstId = (id: number) => _.isEqual(firstId, id);

  const fileRestoreIcon: TIcon = "settings_backup_restore";

  const actionList = [
    {
      id: "restore",
      icon: fileRestoreIcon,
      text: "Restore",
      onClick: (file: IFile) => {
        setVersionFileToRestore(file);
        setShowRestoreModal(true);
      },
    },
  ];

  const actionDotsMobile = (file: IRow): JSX.Element => {
    return !_.isEqual(firstId, file?.id) && currentFileHistory.file_id ? (
      <DotsMenu
        dotsDirection="vertical"
        actionList={actionList}
        context={file}
      />
    ) : (
      <></>
    );
  };

  const columns = [
    {
      header: "Name",
      id: "name",
      colspan: { xs: "3", sm: "6" },
      field: "name",
      body: ({ changes, file, id }: IRow) => (
        <FileName
          isGrayIcon={!isFirstId(id)}
          name={`${changes?.name || file.name}`}
          type={file?.type}
        />
      ),
    },
    {
      header: "Date",
      id: "updated_at",
      colspan: { xs: "2" },
      field: "updated_at",
      parsedString: ({ created_at }: IRow) => formatDate(created_at),
      fixedWidth: { xs: "100px", sm: "2" },
    },
    {
      header: "Hour",
      id: "hour",
      colspan: { xs: "2" },
      field: "hour",
      parsedString: ({ created_at }: IRow) => formatHour(created_at),
      fixedWidth: { xs: "100px", sm: "2" },
      collapsable: true,
    },
    {
      header: "Type",
      id: "type",
      colspan: { sm: "2" },
      field: "type",
      parsedString: ({ file }: IRow) => (file?.type ? `.${file.type}` : ""),
      collapsable: true,
    },
    {
      header: "Edited By",
      id: "edited_by",
      colspan: { xs: "2", sm: "2" },
      field: "edited_by",
      parsedString: ({ user }: IRow) => user || ` `,
      collapsable: true,
    },
    {
      header: "Size",
      id: "size",
      colspan: { xs: "2", sm: "2" },
      field: "size",
      parsedString: ({ file }: IRow) =>
        file?.size ? formatWeight(file.size, true) : "",
      collapsable: true,
    },
    {
      header: "",
      id: "restore",
      colspan: { xs: "2" },
      field: "",
      body: (file: IRow) => fileStatusButton(file),
      collapsable: true,
      hiddenMobileTitle: true,
      fixedWidth: { xs: "40px", lg: "212px" },
    },
    {
      header: "",
      id: "action",
      colspan: { xs: "2" },
      field: "",
      body: (file: IRow) => actionDotsMobile(file),
      collapsableDesktop: true,
      hiddenMobileTitle: true,
      fixedWidth: { xs: "40px", lg: "0px" },
    },
  ];

  useEffect(() => {
    dispatch(getVersionsHistory(currentFileHistory.id));
  }, []);

  const handleSubmit = () => {
    if (versionFileToRestore) {
      dispatch(
        restoreVersion({
          documentId: Number(documentId),
          versionId: versionFileToRestore.id,
        }),
      ).then(() => {
        const text = `
        <strong>Previous Version Restored</strong>
        <br>
        <b>“${currentFileHistory?.name}.${currentFileHistory?.type}”</b> version of <b>${formatDate(
          currentFileHistory?.file.created_at,
        )}</b> - at <b>${formatHour(
          currentFileHistory?.file.created_at,
        )}hs.</b> has been restored successfully.
        `;
        createToast(text, "success", dispatch);
        setShowRestoreModal(false);
        navigate(
          `/admin/file-management/${currentFileHistory.parent_id || ""}`,
          {
            replace: true,
          },
        );
      });
    }
  };

  const subtitle = currentFileHistory.file_id
    ? "All file versions are shown here. You may restore any available versions of this file."
    : "All folder versions are shown here. We don’t currently support restoring folders.";
  return (
    <>
      <ActionModal
        show={showRestoreModal}
        onHide={() => setShowRestoreModal(false)}
        title="Restore Version"
        subtitle={`“${versionFileToRestore?.changes?.name || versionFileToRestore?.file?.name}.${versionFileToRestore?.file?.type}” saved on ${formatDate(versionFileToRestore?.created_at!)} - at ${formatHour(versionFileToRestore?.created_at!)}hs will be restored. It will replace the current version.`}
        buttonLeftText="Cancel"
        buttonRightText="Continue"
        onClickLeftButton={() => setShowRestoreModal(false)}
        onClickRightButton={handleSubmit}
      />
      <div className="v-version-history o-admin-screen-container o-padding-module w-100">
        {data && <AdminHeaderModule folder={_data} hideBreadcrumb />}

        <p className="o-ft-lg o-cl-grey-100">{subtitle}</p>
        <div>
          {pending ? (
            <div className="d-flex justify-content-center mt-5">
              <Spinner
                className="o-ft-xl o-ft-3xl@md"
                color={colors.brandColorSecondary}
              />
            </div>
          ) : (
            <Table
              dynamicRowClassName={({ id }: IRow) => {
                let className = "o-bg-transparent";
                if (responsive.md) {
                  className = "o-bg-grey-300:hover";
                  if (
                    selectedFiles.some((selectedFile) =>
                      _.isEqual(selectedFile.id, id),
                    )
                  ) {
                    className = "o-bg-grey-300 c-table__row--selected";
                  }
                }
                return className;
              }}
              data={historyData}
              {...{ columns }}
              extraClassName={`${responsive.md && "c-table--selectable mt-md-3"}`}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VersionHistory;
