/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";

import { Form as FormRB } from "react-bootstrap";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";

interface IForm {
  children: (methods: UseFormReturn<any, any>) => React.ReactNode;
  onSubmit: any;
  schema: any;
  defaultValues: any;
}
const Form: React.FC<IForm> = ({
  children,
  onSubmit,
  schema,
  defaultValues,
}) => {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <FormRB noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        {children(methods)}
      </FormRB>
    </FormProvider>
  );
};

export default Form;
