import { TContactCategory } from "@models/ContactCategory.type";
// import { TContactSpecialty } from '@models/ContactsaByType.type';
import { IFile } from "@models/files/File.type";
import { v4 as uuidv4 } from "uuid";
import { IFilterDropdownItem } from "@components/filters/FilterDropdownList";
import _ from "lodash";
import MaterialIcon from "./MaterialIcon";
import classNames from "classnames";
import React from "react";

export type TCheckbox = TContactCategory | IFile | IFilterDropdownItem;

interface ICheckboxProps {
  item: TCheckbox;
  onChange: (obj: TCheckbox, nextStatus: boolean) => void;
  checked: boolean;
  className?: string;
  showName?: boolean;
  variant?: "icon";
  size?: "small" | "medium" | "large";
  textPosition?: "left" | "right";
}

const Checkbox = ({
  item,
  onChange,
  checked,
  className,
  showName = true,
  variant,
  size = "medium",
  textPosition = "right",
}: ICheckboxProps) => {
  const { id, name } = item;
  const key = id + uuidv4();

  const checkboxClass = classNames({
    "c-checkbox--variant-icon": _.isEqual(variant, "icon"),
  });

  const sizeClass = `c-checkbox__check--${size}`;

  return (
    <div
      className={`c-checkbox ${className} ${checkboxClass} align-items-center`}
    >
      {showName && textPosition === "left" && (
        <label className="o-ft-sm-600 o-cursor-pointer mx-2" htmlFor={key}>
          {name}
        </label>
      )}
      <div className="c-checkbox__check-container">
        <input
          onChange={() => onChange(item, !checked)}
          className="c-checkbox__input o-cursor-pointer"
          type="checkbox"
          id={key}
          name=""
          checked={checked}
        />
        <div className={`c-checkbox__check ${sizeClass}`}>
          {checked && _.isEqual(variant, "icon") && (
            <MaterialIcon icon="check" size={18} />
          )}
        </div>
      </div>
      {showName && textPosition === "right" && (
        <label
          className="c-checkbox__name o-ft-sm-400 o-cursor-pointer d-inline"
          htmlFor={key}
        >
          {name}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
