import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  IPost,
  useGetAdminPost,
  useTooglePublishedPost,
} from "@redux/posts/api";
import RenderPost from "@sections/Posts/RenderPost";
import Button from "@components/Button";
import { ChipContainer, Chip, ButtonContainer, PostContainer } from "./styled";
import { createToast } from "@helpers/createToast";
import useAppDispatch from "@hooks/useAppDispatch";
import { Spinner } from "react-activity";
import { colors } from "@theme/colors";

const ShowPost = () => {
  const { postId } = useParams();
  const dispatch = useAppDispatch();
  const { data: postData, isFetching } = useGetAdminPost({ id: postId });
  const [tooglePublishedPost, { isLoading }] = useTooglePublishedPost();
  const post: IPost = useMemo(() => {
    if (postData) {
      return postData.data;
    }
    return [];
  }, [postData]);

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center mt-5 flex-column align-items-center gap-4">
        <Spinner
          className="o-ft-xl o-ft-3xl@md"
          color={colors.brandColorSecondary}
        />
      </div>
    );
  }

  const tooglePost = () => {
    tooglePublishedPost({
      id: post.id,
      is_published: !post.is_published,
    })
      .unwrap()
      .then(() => {
        createToast(
          `Post ${post.is_published ? "un" : ""}published successfully!`,
          "success",
          dispatch,
        );
      });
  };

  return (
    <div className="d-flex flex-column align-items-center gap-2">
      <PostContainer>
        <ChipContainer $backgroundColor={colors.brandColorPrimary}>
          <Chip $color={colors.white}>
            {post.is_published ? "PUBLISHED" : "DRAFT"}
          </Chip>
        </ChipContainer>
      </PostContainer>
      <RenderPost post={post} />
      <ButtonContainer>
        <Button
          value={`${post.is_published ? "Unpublish Post" : "Publish Post"}`}
          onClick={() => tooglePost()}
          isLoading={isLoading}
          disabled={isLoading}
        />
      </ButtonContainer>
    </div>
  );
};

export default ShowPost;
