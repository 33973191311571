import React, { useState, useEffect, useRef } from "react";

interface TextoAjustadoProps {
  texto: string;
  maxLines: number;
}

const TextoAjustado: React.FC<TextoAjustadoProps> = ({ texto, maxLines }) => {
  const [ajustadoTexto, setAjustadoTexto] = useState<string>(texto);
  const contenedorRef = useRef<HTMLDivElement | null>(null);

  const ajustarTextoConPuntos = () => {
    const element = contenedorRef.current;
    if (!element) return; // Verifica si el elemento existe

    const originalText = texto;
    const words = originalText.split(" ");
    let tempText = "";
    let lineCount = 0;

    // Bucle para construir el texto línea por línea
    for (let i = 0; i < words.length; i += 1) {
      const newText = tempText + (tempText ? " " : "") + words[i];
      element.innerText = newText;

      if (element.scrollHeight > element.clientHeight) {
        // Verifica si se ha excedido el alto
        if (lineCount >= maxLines) {
          // Si excede las líneas permitidas, agrega "..."
          setAjustadoTexto(`${tempText}...`);
          break;
        }
        lineCount += 1;
      } else {
        tempText = newText; // Actualiza el texto temporal si no excede el espacio
      }
    }
  };
  useEffect(() => {
    ajustarTextoConPuntos();
  }, [texto]);

  return (
    <div
      ref={contenedorRef}
      style={{
        height: `${contenedorRef?.current?.clientHeight}px`,
        width: `${contenedorRef?.current?.clientWidth}px`,
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: maxLines,
        WebkitBoxOrient: "vertical",
      }}
    >
      {ajustadoTexto}
    </div>
  );
};

export default TextoAjustado;
