/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGetContacts,
  useGetContactsBySearch,
  useGetNextPage,
  useGetTypes,
} from "@redux/contacts/api";

import CardList from "@components/CardList";
import Chip from "@components/Chip";
import ContactTable from "@components/ContactTable";
import Error from "@components/Error";
import HeaderModule from "@components/HeaderModule";
import { IFilter } from "@components/Search/shared/Results";
import Order from "./shared/Filters/Order";
import PaginationLoader from "@components/PaginationLoader/PaginationLoader";
import Search from "@components/Search/SearchOld";
import { Spinner } from "react-activity";
import SwitchMode from "@components/SwitchMode";
import Types from "./shared/Filters/Types";
import { colors } from "@theme/colors";
import emptyState from "../../assets/no-results.svg";
import orderBy from "lodash/orderBy";
import useAppSelector from "@hooks/useAppSelector";
import useWindowSize from "@hooks/useWindowSize";

const baseSrcUrl =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "";

const orderOptions = [
  { id: 1, name: "Newest" },
  { id: 2, name: "Oldest" },
  { id: 3, name: "A-Z" },
  { id: 4, name: "Z-A" },
];

const searchFilters: IFilter[] = [{ id: 1, name: "tag" }];

const orderData = (data: any[], order: string) => {
  if (order === "Newest" || order === "Oldest") {
    return orderBy(data, ["created_at"], [order === "Newest" ? "desc" : "asc"]);
  }
  if (order === "A-Z" || order === "Z-A") {
    return orderBy(data, ["name"], [order === "Z-A" ? "desc" : "asc"]);
  }

  return data;
};

const Contacts: React.FC = () => {
  const module = "contacts";
  const { responsive } = useWindowSize();

  const [searchValue, setSearchvalue] = useState("");
  const [order, setOrder] = useState("Newest");
  const [selectedTypes, setSelectedTypes] = useState<
    { id: number; name: string }[]
  >([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [filterTag, setFilterTag] = useState<IFilter | null>(null);
  const [displayedData, setDisplayedData] = useState<any[]>([]);
  const [nextUrl, setNextUrl] = useState("");

  const filterByTagsPath = `filters[tags]=${selectedTags.join(",")}`;
  const filterByTypesPath = `filters[contact_type_id]=${selectedTypes.map((t) => t.id).join(",")}`;
  const filterByQueryPath = `filters[q]=${searchValue}`;

  const url = `api/search/contacts?${filterByTagsPath}&${filterByTypesPath}`;
  const getContactsURL =
    !selectedTags.length && !selectedTypes.length ? "api/contacts" : url;
  const searchUrl = filterTag
    ? `api/contact-tags?${filterByQueryPath}`
    : `api/search/contacts?${filterByQueryPath}&${filterByTagsPath}&${filterByTypesPath}`;

  const {
    data: contacts,
    isFetching,
    isLoading,
  } = useGetContacts({ url: getContactsURL });

  const { data: nextPageContacts, isFetching: isLoadingNext } = useGetNextPage(
    { url: nextUrl },
    { skip: !nextUrl },
  );

  const { data: searchData, isFetching: isLoadingSearch } =
    useGetContactsBySearch({ url: searchUrl });

  const { data: types } = useGetTypes();

  const resetData = useCallback(() => {
    setDisplayedData(orderData(contacts?.data, order));
  }, [contacts, order]);

  const handleOnType = (value: string) => {
    setSearchvalue(value);

    if (value.length === 0) {
      resetData();
    }

    if (filterTag) {
      const unexistentTag = selectedTags.find((tag) => !value.includes(tag));
      if (unexistentTag) {
        setSelectedTags((p) => p.filter((tag) => tag !== unexistentTag));
      }
    }
  };

  const { views } = useAppSelector((state) => state.toggle);

  const handleScroll = (event: any) => {
    const { target } = event;
    if (
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1 &&
      target.scrollHeight - target.scrollTop >= target.clientHeight - 1 &&
      nextPageContacts?.links.next !== null &&
      contacts.links.next !== null
    ) {
      setNextUrl(nextPageContacts?.links.next || contacts.links.next);
    }
  };

  const selectTypes = (option: { id: number; name: string }) => {
    setSelectedTypes((p) =>
      p.includes(option) ? p.filter((t) => t.id !== option.id) : [...p, option],
    );
  };

  const selectOrder = (option: { id: number; name: string }) => {
    setOrder(option.name);
  };

  const handleSelectItem = (item: { id: number; name: string }) => {
    if (filterTag) {
      setSelectedTags((p) =>
        p.includes(item.name)
          ? p.filter((tag) => tag !== item.name)
          : [...p, item.name],
      );
    } else {
      setDisplayedData([
        searchData.find((contact: any) => contact.id === item.id),
      ]);
    }
  };

  const handleOnClear = () => {
    setSearchvalue("");
    setSelectedTags([]);
    resetData();
  };

  const handleOnSelectFilter = (filter: IFilter | null) => {
    setFilterTag(filter || null);
  };

  const formatedSearchResults = useMemo(() => {
    const data = searchValue ? searchData : [];
    const mappedData =
      data.map((contact: any) => ({
        ...contact,
        icon: filterTag ? undefined : "account_circle",
      })) || [];
    return {
      contacts: {
        data: mappedData,
        meta: {},
        links: {},
      },
    };
  }, [searchData, filterTag, searchValue]);

  useEffect(() => {
    resetData();
  }, [resetData]);

  useEffect(() => {
    if (!nextPageContacts) return;
    setDisplayedData((p) => [...p, ...nextPageContacts.data]);
  }, [nextPageContacts]);

  useEffect(() => {
    setSearchvalue(selectedTags.join(" "));
  }, [selectedTags]);

  const renderContent = () => {
    if (isFetching || isLoading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            className="v-contacts__spinner"
            color={colors.brandColorSecondary}
          />
        </div>
      );
    }

    if (displayedData.length) {
      return views[module].grid ? (
        <CardList data={displayedData} />
      ) : (
        <ContactTable data={displayedData} />
      );
    }

    return (
      <Error
        src={baseSrcUrl + emptyState}
        title="No Results Found!"
        description="We couldn't find any matches for your search. Please try again."
      />
    );
  };

  return (
    <div className="o-screen-container" onScroll={handleScroll}>
      <HeaderModule title="Contacts" withBreadcrumb />

      <div className="d-flex justify-content-between align-items-center mb-4 flex-column flex-md-row">
        <div
          className="d-flex align-items-center"
          style={{
            boxShadow: "6px 10px 15px -4px rgba(0,0,0,.08)",
            width: responsive.md ? "60%" : "100%",
          }}
        >
          <Types
            options={types || []}
            onSelect={selectTypes}
            selected={selectedTypes}
          />
          <Search
            onType={handleOnType}
            onSelectItem={handleSelectItem}
            onClear={handleOnClear}
            onSelectFilter={handleOnSelectFilter}
            value={searchValue}
            filters={searchFilters}
            results={formatedSearchResults}
            placeholder="Search and filter"
            searchResultsTitle="Filter by:"
            isFetching={isLoadingSearch}
            borderStyle={false}
            viewAllBtn={false}
          />
        </div>

        <div className="d-flex flex-wrap d-md-none align-items-center gap-2 mt-4">
          {selectedTypes.map((type) => (
            <Chip
              key={type.id}
              name={type.name}
              onClick={() => {
                const filters = selectedTypes.filter(
                  (filter) => filter.id !== type.id,
                );
                setSelectedTypes(filters);
              }}
              size="small"
            />
          ))}

          {selectedTypes.length ? (
            <Chip
              main
              name="Clear All"
              onClick={() => setSelectedTypes([])}
              size="small"
            />
          ) : null}
        </div>

        <div className="d-flex align-items-center align-self-end align-self-md-center mt-4 mt-md-0">
          <div className="d-flex align-items-center gap-2 justify-content-end d-md-flex">
            <div className="d-flex">
              <SwitchMode module={module}>
                <div className="mx-1 d-flex">
                  <SwitchMode.Icon mode="grid" />
                </div>
                <div className="mx-1 d-flex">
                  <SwitchMode.Icon mode="list" />
                </div>
              </SwitchMode>
            </div>
          </div>
          <Order options={orderOptions} onSelect={selectOrder} />
        </div>
      </div>

      <div className="d-none align-items-center gap-2 d-md-flex my-4">
        {selectedTypes.map((type) => (
          <Chip
            key={type.id}
            name={type.name}
            onClick={() => {
              const filters = selectedTypes.filter(
                (filter) => filter.id !== type.id,
              );
              setSelectedTypes(filters);
            }}
            size="small"
          />
        ))}

        {selectedTypes.length ? (
          <Chip
            main
            name="Clear All"
            onClick={() => setSelectedTypes([])}
            size="small"
          />
        ) : null}
      </div>

      <div>{renderContent()}</div>

      {isLoadingNext && (
        <PaginationLoader placeholder="Loading more results..." />
      )}
    </div>
  );
};

export default Contacts;
