/* eslint-disable react/jsx-props-no-spreading */
import { useController, useFormContext } from "react-hook-form";

import { Form } from "react-bootstrap";
import React from "react";
import get from "lodash/get";
import styled from "styled-components";
import { colors } from "@theme/colors";

export interface IInput {
  name: string;
  label: string;
  isRequired?: boolean;
  helperText?: string;
  placeholder?: string;
  disabled?: boolean;
  temporaty_url?: string;
}

export const ErrorText = styled(Form.Text)`
  color: red !important;
`;

const FormControl = styled(Form.Control)`
  &:focus {
    border-color: ${colors.brandColorPrimary} !important;
    box-shadow: 0 0 0 0.25rem ${colors.brandColorPrimary}25 !important;
  }
`;
const Input: React.FC<IInput> = ({
  name,
  label,
  isRequired = false,
  helperText,
  placeholder,
  disabled = false,
}) => {
  const { field } = useController({ name });
  const {
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name)?.message;
  return (
    <Form.Group className="mt-4 o-ft-xs-600 o-cl-grey-200">
      <Form.Label>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Form.Label>
      <FormControl {...field} placeholder={placeholder} disabled={disabled} />
      <Form.Text>{helperText}</Form.Text>
      {error && <ErrorText>{String(error)}</ErrorText>}
    </Form.Group>
  );
};

export default Input;
