/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorText, IInput } from "./Input";
/* eslint-disable react/jsx-props-no-spreading */
import { useController, useFormContext } from "react-hook-form";

import CreatableSelect from "react-select/creatable";
import { Form } from "react-bootstrap";
import React from "react";
import { customStylesTags } from "./styles/Select.styles";
import get from "lodash/get";

const AddTag = (inputValue: any) => (
  <div className="add-tags">+ Add tag &quot;{inputValue}&quot;</div>
);

interface ISelect extends IInput {
  options: Array<{ label: string; value: string | number }>;
  handleCreateTag: (value: string) => void;
}

const Select: React.FC<ISelect> = ({
  name,
  label,
  isRequired = false,
  helperText,
  disabled = false,
  options,
  handleCreateTag,
}) => {
  const { field } = useController({ name });
  const {
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name)?.message;

  return (
    <Form.Group className="mt-4 o-ft-xs-600 o-cl-grey-200">
      <Form.Label>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Form.Label>
      <CreatableSelect
        {...field}
        isMulti
        value={field.value.map((val: string) => {
          const opt = options.find((op) => op.value === val);
          return { label: opt?.label, value: val };
        })}
        options={options}
        onChange={(value) => field.onChange(value.map((v) => v.value))}
        classNamePrefix="react-select"
        styles={customStylesTags}
        placeholder="Select or create tags"
        components={{ DropdownIndicator: null }}
        onCreateOption={handleCreateTag}
        formatCreateLabel={AddTag}
        noOptionsMessage={() =>
          options.length === 0
            ? "No tags created yet. Start by adding your first tag"
            : "loading..."
        }
      />
      {error && <ErrorText>{String(error)}</ErrorText>}
    </Form.Group>
  );
};

export default Select;
