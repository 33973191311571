/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { IFolderStructure, IIdentifiedFile } from "@models/files/File.type";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ReactElement,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import {
  addFileFV2,
  createFolderFromDocumentsV2,
  getDocuments,
} from "@redux/documents/thunks/documentsThunk";

import { EToastTypes } from "@models/toast/Toast.type";
import { addFolder } from "@redux/files/slices/fileSlice";
import { createToast } from "@helpers/createToast";
import debounce from "lodash/debounce";
import { updateToast, removeToast } from "@redux/toasts/slices/toastsSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import { TTag } from "@models/Tag.type";

const excludeFiles = [".DS_Store"];

enum DocumentsActions {
  SET_ELEMENT_TO_MOVE = "SET_ELEMENT_TO_MOVE",
  SET_SEARCH_DOCUMENT = "SET_SEARCH_DOCUMENT",
  SET_FILES_TO_UPLOAD = "SET_FILES_TO_UPLOAD",
  SET_FOLDER_TO_UPLOAD = "SET_FOLDER_TO_UPLOAD",
  RESTART_STATE = "RESTART_STATE",
  SET_FILES_UPLOADED_SUCCESSFULLY = "SET_FILES_UPLOADED_SUCCESSFULLY",
  SET_FILES_UPLOADED_WITH_ERROR = "SET_FILES_UPLOADED_WITH_ERROR",
  SET_FILES_TO_UPLOAD_ON_FOLDERS = "SET_FILES_TO_UPLOAD_ON_FOLDERS",
  SET_IS_UPLOADING_FILES = "SET_IS_UPLOADING_FILES",
}

interface IBaseSearchDocument {
  query: string;
  parentId?: number;
}

interface IOnlyFolders extends IBaseSearchDocument {
  only_folders: boolean;
  only_files?: never;
}

interface IOnlyFiles extends IBaseSearchDocument {
  only_folders?: never;
  only_files: boolean;
}

type ResourceType = {
  id: number;
  name: string;
  parent_id?: number | null;
  type?: string | null;
  document_tags?: TTag[];
};

type DocumentsState = {
  elementToMove: ResourceType | null;
  searchDocument: string;
  filesToUpload: Array<IIdentifiedFile>;
  foldersToUpload: IFolderStructure[];
  filesUploadedSuccessfully: number;
  filesUploadedWithError: number;
  filesToUploadOnFolder: number;
  isUploadingFiles: boolean;
};

type DocumentsAction = {
  type: DocumentsActions;
  payload?: number | boolean | any;
};

const initialState: DocumentsState = {
  elementToMove: null,
  searchDocument: "",
  filesToUpload: [],
  foldersToUpload: [],
  filesUploadedSuccessfully: 0,
  filesUploadedWithError: 0,
  filesToUploadOnFolder: 0,
  isUploadingFiles: false,
};

type DocumentsContextType = {
  setElementToMove: (elementToMove: ResourceType) => void;
  setSearchDocument: (searchDocumentProps: IOnlyFiles | IOnlyFolders) => void;
  restartDocuments: () => void;
  setFilesToUpload: (files: Array<IIdentifiedFile>) => void;
  setFoldersToUpload: (folders: IFolderStructure[]) => void;
  restartDocumentsState: () => void;
  uploadFolders: ({ parentId }: { parentId?: number | null }) => Promise<void>;
  uploadFiles: ({ parentId }: { parentId?: number | null }) => Promise<any[]>;
  setFilesToUploadOnFolders: (cantFiles: number) => void;
  setIsUploadingFiles: (isUploadingFiles: boolean) => void;
} & DocumentsState;

export const DocumentsContext = createContext<DocumentsContextType | null>(
  null,
);

const moveToReducer = (state: DocumentsState, action: DocumentsAction) => {
  switch (action.type) {
    case DocumentsActions.SET_ELEMENT_TO_MOVE:
      return {
        ...state,
        elementToMove: action.payload,
      };
    case DocumentsActions.SET_SEARCH_DOCUMENT:
      return {
        ...state,
        searchDocument: action.payload,
      };
    case DocumentsActions.SET_FILES_TO_UPLOAD:
      return {
        ...state,
        filesToUpload: action.payload,
      };
    case DocumentsActions.SET_FOLDER_TO_UPLOAD:
      return {
        ...state,
        foldersToUpload: action.payload,
      };
    case DocumentsActions.SET_FILES_UPLOADED_SUCCESSFULLY:
      return {
        ...state,
        filesUploadedSuccessfully: state.filesUploadedSuccessfully + 1,
      };
    case DocumentsActions.SET_FILES_UPLOADED_WITH_ERROR:
      return {
        ...state,
        filesUploadedWithError: state.filesUploadedWithError + 1,
      };
    case DocumentsActions.SET_FILES_TO_UPLOAD_ON_FOLDERS:
      return {
        ...state,
        filesToUploadOnFolder: state.filesToUploadOnFolder + action.payload,
      };
    case DocumentsActions.SET_IS_UPLOADING_FILES:
      return {
        ...state,
        isUploadingFiles: action.payload,
      };
    case DocumentsActions.RESTART_STATE:
      return initialState;
    default:
      return state;
  }
};

const DocumentsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatchAction] = useReducer(moveToReducer, initialState);

  const dispatch = useAppDispatch();

  const fetchDocuments = useCallback(
    (searchDocumentProps: IOnlyFiles | IOnlyFolders) => {
      dispatch(getDocuments(searchDocumentProps));
    },
    [dispatch],
  );

  const debouncedFetchDocuments = useCallback(debounce(fetchDocuments, 500), [
    fetchDocuments,
  ]);

  const setElementToMove = (elementToMove: any) => {
    dispatchAction({
      type: DocumentsActions.SET_ELEMENT_TO_MOVE,
      payload: elementToMove,
    });
  };

  const setSearchDocument = (
    searchDocumentProps: IOnlyFiles | IOnlyFolders,
  ) => {
    const { query } = searchDocumentProps;
    dispatchAction({
      type: DocumentsActions.SET_SEARCH_DOCUMENT,
      payload: query,
    });
    debouncedFetchDocuments(searchDocumentProps);
  };

  const restartDocuments = () => {
    dispatchAction({
      type: DocumentsActions.RESTART_STATE,
    });
  };

  const setFilesToUpload = (files: Array<IIdentifiedFile>) => {
    dispatchAction({
      type: DocumentsActions.SET_FILES_TO_UPLOAD,
      payload: files,
    });
  };

  const setFoldersToUpload = (folders: IFolderStructure[]) => {
    dispatchAction({
      type: DocumentsActions.SET_FOLDER_TO_UPLOAD,
      payload: folders,
    });
  };

  const restartDocumentsState = () => {
    dispatchAction({
      type: DocumentsActions.RESTART_STATE,
    });
  };

  const setAddFilesUploadedSuccessfully = () => {
    dispatchAction({
      type: DocumentsActions.SET_FILES_UPLOADED_SUCCESSFULLY,
    });
  };

  const setAddFilesUploadedWithError = () => {
    dispatchAction({
      type: DocumentsActions.SET_FILES_UPLOADED_WITH_ERROR,
    });
  };

  const setFilesToUploadOnFolders = (cantFiles: number) => {
    dispatchAction({
      type: DocumentsActions.SET_FILES_TO_UPLOAD_ON_FOLDERS,
      payload: cantFiles,
    });
  };

  const setIsUploadingFiles = (isUploadingFile: boolean) => {
    dispatchAction({
      type: DocumentsActions.SET_IS_UPLOADING_FILES,
      payload: isUploadingFile,
    });
  };

  useEffect(() => {
    const totalFilesUploaded =
      state.filesUploadedSuccessfully + state.filesUploadedWithError;
    const filesToUploadCount = state.isUploadingFiles
      ? state.filesToUpload.length
      : state.filesToUploadOnFolder;
    let uploadStatus = "...";
    if (state.isUploadingFiles) {
      if (state.filesToUpload.length === totalFilesUploaded) {
        uploadStatus = "successfully!";
      }
    } else if (state.filesToUploadOnFolder === totalFilesUploaded) {
      uploadStatus = "successfully!";
    }
    const uploadAllFiles =
      state.filesToUploadOnFolder === totalFilesUploaded ||
      state.filesToUpload === totalFilesUploaded;

    if (uploadAllFiles) {
      dispatch(removeToast("filesUploading"));
    }

    dispatch(
      updateToast({
        id: "filesUploading",
        type: EToastTypes.WARNING,
        text: `
        <div class="d-flex">
          <div class="loader"></div>
          <span>
            ${state.filesUploadedSuccessfully} files out of ${filesToUploadCount} uploaded ${uploadStatus}
          </span>
        </div>
      `,
        withClose: true,
        autohide: uploadAllFiles,
      }),
    );
    if (
      state.filesToUploadOnFolder === totalFilesUploaded ||
      state.filesToUpload.length === totalFilesUploaded
    ) {
      setTimeout(() => {
        restartDocumentsState();
      }, 2000);
    }
  }, [state.filesUploadedSuccessfully, state.filesUploadedWithError]);

  const uploadFolderElements = async ({
    folder,
    parentId,
  }: {
    folder: IFolderStructure;
    parentId?: number | null;
  }): Promise<void> => {
    try {
      const res = await dispatch(
        createFolderFromDocumentsV2({
          params: {
            name: folder.name,
            action: "keep_both",
            parent_id: parentId,
            document_tags: [],
          },
        }),
      ).unwrap();

      const isRootFolder = state.foldersToUpload.find(
        (fold: IFolderStructure) => fold.name === folder.name,
      );

      if (isRootFolder) {
        dispatch(addFolder(res));
      }

      const CHUNK_SIZE = 15;

      // Subir archivos en chunks de 15
      for (let i = 0; i < folder.files.length; i += CHUNK_SIZE) {
        const chunk = folder.files.slice(i, i + CHUNK_SIZE);
        await Promise.all(
          chunk.map((file: any) => {
            if (excludeFiles.includes(file.name)) {
              setAddFilesUploadedSuccessfully();
              return Promise.resolve();
            }
            return dispatch(
              addFileFV2({
                params: {
                  file,
                  action: "keep_both",
                  parentId: res.id,
                },
              }),
            )
              .unwrap()
              .then(() => {
                setAddFilesUploadedSuccessfully();
              })
              .catch(() => {
                setAddFilesUploadedWithError();
                createToast(
                  `We have an error uploading the file "${file.name}"`,
                  "danger",
                  dispatch,
                );
              });
          }),
        );
      }

      // Subir carpetas anidadas de forma secuencial
      for (const subFolder of folder.folders) {
        await uploadFolderElements({
          folder: subFolder,
          parentId: res.id,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadFolders = async ({ parentId }: { parentId?: number | null }) => {
    const CHUNK_SIZE = 15;
    const allResults = [];
    const chunks = [];

    const uploadChunk = async (chunk: any[]) => {
      const chunkPromises = chunk.map((folder: IFolderStructure) => {
        return uploadFolderElements({
          folder,
          parentId,
        });
      });
      return Promise.all(chunkPromises);
    };

    for (let i = 0; i < state.foldersToUpload.length; i += CHUNK_SIZE) {
      const chunk = state.foldersToUpload.slice(i, i + CHUNK_SIZE);
      chunks.push(chunk);
    }

    for (const chunk of chunks) {
      const chunkResult = await uploadChunk(chunk);
      allResults.push(...chunkResult);
    }
  };

  const uploadFiles = async ({ parentId }: { parentId?: number | null }) => {
    setIsUploadingFiles(true);
    const CHUNK_SIZE = 15;
    const allResults = [];
    const uploadChunk = async (chunk: any[]) => {
      const chunkPromises = chunk.map((file: any) => {
        return dispatch(
          addFileFV2({
            params: {
              file: file.file,
              action: file.action || "keep_both",
              parentId,
            },
          }),
        )
          .unwrap()
          .then((res) => {
            setAddFilesUploadedSuccessfully();
            return res;
          })
          .catch(() => {
            setAddFilesUploadedWithError();
            createToast(
              `We have an error uploading the file "${file.name}"`,
              "danger",
              dispatch,
            );
          });
      });
      return Promise.all(chunkPromises);
    };

    const chunks = [];
    for (let i = 0; i < state.filesToUpload.length; i += CHUNK_SIZE) {
      const chunk = state.filesToUpload.slice(i, i + CHUNK_SIZE);
      chunks.push(chunk);
    }

    for (const chunk of chunks) {
      const chunkResult = await uploadChunk(chunk);
      allResults.push(...chunkResult);
    }
    dispatch(getDocuments({ parentId: parentId || undefined }));

    return allResults;
  };

  const contextValue = useMemo(
    () => ({
      ...state,
      setElementToMove,
      restartDocuments,
      setSearchDocument,
      setFilesToUpload,
      setFoldersToUpload,
      restartDocumentsState,
      uploadFolders,
      setFilesToUploadOnFolders,
      uploadFiles,
      setIsUploadingFiles,
    }),
    [
      state,
      setElementToMove,
      restartDocuments,
      setSearchDocument,
      setFilesToUpload,
      setFoldersToUpload,
      restartDocumentsState,
      uploadFolders,
      setFilesToUploadOnFolders,
      uploadFiles,
      setIsUploadingFiles,
    ],
  );

  return (
    <DocumentsContext.Provider value={contextValue}>
      {children}
    </DocumentsContext.Provider>
  );
};

export default DocumentsProvider;
